angular.module('omega').service('SearchStorageService', function (API_BASE_URL, MAX_SAVED_SEARCHES, $http, $q, $rootScope, localStorageService) {

    // key for local storage backup storage in case authentication has failed (no username)
    const key = 'savedSearches';

    this.list = function () {
        if ($rootScope.username) {
            return $http.get(API_BASE_URL + 'getUserSavedQueries.xqy', {params: {username: $rootScope.username}}).then(function (results) {
                return results.data;
            });
        } else {
            return $q.resolve(localStorageService.get(key));
        }
    };

    this.store = function (searchData) {
        if (!searchData.terms) return;

        if ($rootScope.username) {
            return $http.post(API_BASE_URL + 'writeUserSavedQuery.xqy', searchData).then(function () {
                return 'ok';
            });
        } else {
            return this.list().then(function (savedSearches) {
                if (savedSearches && savedSearches.length) {
                    if (savedSearches.length >= MAX_SAVED_SEARCHES) savedSearches.shift();
                    savedSearches.push(searchData);
                }
                else savedSearches = [searchData];

                localStorageService.set(key, savedSearches);

                return $q.resolve('ok');
            });
        }
    };

    this.destroy = function (searchData) {
        if ($rootScope.username) {
            return $http.get(API_BASE_URL + 'deleteUserSavedQuery.xqy', {
                params: {
                    username: $rootScope.username,
                    queryId: searchData.id
                }
            }).then(function (results) {
                return results.data;
            });
        } else {
            return this.list().then(function (savedSearches) {
                if (!savedSearches.length) return;

                for (var i = 0; i < savedSearches.length; i++) {
                    if (id === savedSearches[i].id) {
                        savedSearches.splice(i, 1);
                        localStorageService.set(key, savedSearches);
                    }
                }
                return $q.resolve('ok');
            });
        }
    };

});
