app.controller('ResourceStorageCtrl', function ($window, $filter, $scope, ResourceStorageService, ResourceService, FlashMessageService, GAService) {

    var self = this;

    self.show = function (resource, searchProfileId) {
        ResourceService.show(searchProfileId, resource.uri);
        GAService.event('Search', 'Reuse Saved Search Item');
    };

    self.store = function (resource) {
        ResourceStorageService.store(resource).then(function () {
            FlashMessageService.show($filter('translate')('messages.resources.stored'), 'success');
            GAService.event('Search', 'Save Search Item');
            ResourceStorageService.list().then(function (results) {
                $scope.storage.savedResources = results;
            });

            // track Piwik Analytics Goal 2 : Save result
            if (window._paq) window._paq.push(['trackGoal', 2]);
        });
    };

    self.update = function (resource) {
        ResourceStorageService.update(resource).then(function () {
            FlashMessageService.show($filter('translate')('messages.resources.updated'), 'success');
            ResourceStorageService.list().then(function (results) {
                $scope.storage.savedResources = results;
            });
        });
    };

    self.destroy = function (resource) {
        for (var i = 0; i < $scope.storage.savedResources.length; i++) {
            if (resource.uri === $scope.storage.savedResources[i].uri) {

                return ResourceStorageService.destroy(resource.id).then(function () {
                    FlashMessageService.show($filter('translate')('messages.resources.removed'), 'success');
                    return ResourceStorageService.list().then(function (results) {
                        $scope.storage.savedResources = results;
                    });
                });

            }
        }
    };

    self.toggleStore = function (resource) {
        if (self.isSaved(resource)) {
            return self.destroy(resource);
        }
        return self.store(resource);
    };

    self.isSaved = function (resource) {
        if (!$scope.storage.savedResources) return false;

        for (var i = 0; i < $scope.storage.savedResources.length; i++) {
            if (resource.uri === $scope.storage.savedResources[i].uri) {
                return true;
            }
        }
        return false;
    };

});
