angular.module('omega').service('FilterService', function ($rootScope, API_BASE_URL, $http, $state, $window, $filter) {

        this.list = function (terms, searchProfileId, collections, conceptsFlatList, page, precision, dateFrom, dateTo,
                              refineConcepts, unfoundTerms, originalConcepts, conceptsOriginalResponse, requestedFilter, creationDateFrom) {
            if (refineConcepts.length) {
                var mode = 'refine-faceting';
            } else {
                var mode = 'faceting';
                refineConcepts = null;
            }

            return $http({
                url: API_BASE_URL + 'basicSearch.xqy',
                method: 'POST',
                data: this.buildFormData(terms, searchProfileId, collections, conceptsFlatList, page, precision, dateFrom, dateTo,
                    mode, refineConcepts, unfoundTerms, null, originalConcepts, requestedFilter, creationDateFrom, conceptsOriginalResponse),
                headers: {'Content-Type': undefined} // prevent changing content-type
            }).then(function (results) {
                return results.data;
            });
        };

        this.buildFormData = function (terms, searchProfileId, collections, conceptsFlatList, page, precision, dateFrom,
                                       dateTo, mode, refineConcepts, unfoundTerms, uris, originalConcepts, requestedFilter, creationDateFrom, conceptsOriginalResponse) {

            dateFrom = typeof dateFrom !== 'undefined' ? dateFrom : null;
            dateTo = typeof dateTo !== 'undefined' ? dateTo : null;
            mode = typeof mode !== 'undefined' ? mode : null;
            unfoundTerms = typeof unfoundTerms !== 'undefined' && unfoundTerms.length ? unfoundTerms.join(' ') : null;
            collections = typeof collections !== 'undefined' && collections.length ? collections : null;
            uris = typeof uris !== 'undefined' ? uris : null;
            requestedFilter = typeof requestedFilter !== 'undefined' ? requestedFilter : null;
            originalConcepts = typeof originalConcepts !== 'undefined' ? originalConcepts : null;

            var formData = new FormData();

            formData.append("searchProfile", searchProfileId);

            // select and format searchProfile's collections
            var collectionUris = [];
            collections.forEach(function (item) {
                if (item.searchProfileId == searchProfileId) {
                    collectionUris.push(item.uri);
                }
            });

            formData.append("targetCollections", collectionUris.join('|'));
            formData.append("resultStartsAt", 1 + (page - 1) * $rootScope.resourcesPerPage);
            formData.append("resultEndsAt", page * $rootScope.resourcesPerPage);
            formData.append('precision', precision);
            formData.append('language', $rootScope.lang);

            if (terms) {
                formData.append("textualQuery", terms);
            }
            if (requestedFilter) {
                formData.append("requestedFilters", requestedFilter);
            }
            if (creationDateFrom) {
                formData.append('creationDateFrom', creationDateFrom);
            }

            if (conceptsFlatList && conceptsFlatList.length) {
                for (var i = 0; i < conceptsFlatList.length; i++) {
                    if (typeof conceptsFlatList[i] !== 'undefined') {
                        formData.append("semanticAndMember", conceptsFlatList[i].join('|'));
                    }
                }
            }

            if (unfoundTerms) {
                formData.append('nonConceptQuery', unfoundTerms);
            }
            if (dateFrom) {
                formData.append('dateFrom', $filter('date')(dateFrom, "yyyy-MM-dd"));
            }
            if (dateTo) {
                formData.append('dateTo', $filter('date')(dateTo, "yyyy-MM-dd"));
            }
            if (mode) {
                formData.append('mode', mode);
            }

            if (conceptsOriginalResponse) {
                formData.append('queryAnalysis', conceptsOriginalResponse);
            }

            // We group refineConcepts by filterId
            if (refineConcepts) {
                var filters = {};
                refineConcepts.forEach(function (item) {
                    if (typeof filters[item.filterId] === 'undefined') {
                        filters[item.filterId] = [];
                    }
                    filters[item.filterId].push(item.value);
                });

                angular.forEach(filters, function (values, filter) {
                    formData.append(filter, values.join('|'));
                });
            }
            if (originalConcepts) {
                var originalConceptUris = [];
                angular.forEach(originalConcepts, function (originalConcept) {
                    originalConceptUris.push(originalConcept.uri);
                });
                if (originalConceptUris.length) {
                    formData.append('originalConcepts', originalConceptUris.join('|'));
                }
            }

            if (uris && uris.length) {
                for (var i = 0; i < uris.length; i++) {
                    formData.append('resourceURI', uris[i]);
                }
            }

            return formData;
        };
    }
);
