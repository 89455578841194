app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.preferredLanguage('EN');
    $translateProvider.useSanitizeValueStrategy(null);

    // strings in English
    $translateProvider.translations('EN', {
      searchBox: {
        title: 'STIP Compass policy explorer',
        search: 'Search',
        page: 'Home',
        searchPlaceholder: 'Start typing to search...',
        clearHistory: 'Clear my search history',
        searchOptions: 'Options',
        searchMode: 'Search Mode',
        datesSelection: 'Filter by date',
        precision: 'Scope',
        precisionHelpText:
          '<strong>Precise</strong> : Retrieve only items where the search terms are contained in areas such as title and abstract.<br><br> <strong>Wide</strong> : Retrieve all items identified.',
        dateFormat: 'MMMM Do, YYYY',
        months: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
        dateFrom: 'From date... (mm/dd/yyyy)',
        dateTo: 'To date... (mm/dd/yyyy)',
        collections: 'Libraries',
        closePanel: 'Close panel',
        resetOptions: 'Reset options',
        selectAllLibraries: 'Select all libraries',
        unselectAllLibraries: 'Unselect all libraries',
        apply: 'Apply',
        reset: 'Reset',
        welcome: 'Welcome to O.N.E Sight',
        typeBelow: 'Start typing below to search :'
      },
      savedResources: {
        title: 'Saved items',
        search: 'Search a saved item...',
        name: 'Name',
        actions: 'Actions',
        noResults: 'No saved items yet'
      },
      savedSearches: {
        title: 'Saved queries',
        queryName: 'query name',
        inputTerms: 'input terms',
        date: 'date',
        actions: 'actions',
        saveSearch: 'Save query',
        save: 'Save',
        noResults: 'No saved searches yet'
      },
      results: {
        loading: 'Loading resources...',
        result: 'result',
        page: 'page',
        of: 'of',
        for: 'for',
        withTheConcept: 'with the concept : ',
        ofResourceType: 'only : ',
        expand: 'Show more details',
        switchViewModeToExpand: 'Switch view mode to show details',
        closeDetail: 'Show less details',
        showMore: 'Show more tags',
        showTags: 'Show tags',
        showLess: 'Show less tags',
        detail: 'Detail',
        general: 'General',
        title: 'Title',
        collection: 'Collection',
        link: 'Link',
        parentLink: 'Parent link',
        identifiers: 'Identifiers',
        texts: 'Texts',
        parent: 'Parent',
        structures: 'Structures',
        dates: 'Dates',
        types: 'Types',
        resultsPerPage: 'Results per page : ',
        saveResult: 'Save this result',
        backToResults: 'Back to results',
        shareResultByEmail: 'Share this result by email',
        newItemsSince: 'New items since',
        send: 'Send',
        emailAddress: 'Email address',
        invalidEmailAddress: 'Invalid email address!',
        pleaseTypeEmailAddress: 'Please type the email address'
      },
      filters: {
        refineAndDiscoverTools: 'Refine tools',
        loading: 'Loading refine options...',
        resourceTypes: 'Types',
        conceptTree: 'Identified from the query',
        suggestedConcepts: 'Identified from the result set',
        apply: 'Apply refining'
      },
      navigation: {
        previous: 'Previous',
        next: 'Next',
        refineTools: 'Refine',
        refineToolsHelpText: 'Narrow down your results'
      },
      screenMode: {
        switchViewMode: 'Switch View mode',
        viewMode: 'View mode',
        list: 'List',
        brickWall: 'Brick Wall',
        whatsNew: "What's new ?"
      },
      messages: {
        generalError: 'An error happened.',
        resources: {
          stored: 'The result has been stored',
          updated: 'The result has been updated',
          removed: 'The result has been removed',
          emailSent: 'Your email has been sent',
          emailFailed: 'Sending email failed'
        },
        searches: {
          stored: 'The query has been stored',
          removed: 'The query has been removed'
        }
      },
      dashboard: {
        title: "What's new in O.N.E Sight ?",
        datesFrom: {
          oneDay: 'Since yesterday',
          twoDays: 'Since two days ago',
          threeDays: 'Since three days ago',
          oneWeek: 'Since a week ago',
          oneMonth: 'Since a month ago'
        },
        loading: 'Loading dashboard...',
        displayResults: 'Display results',
        noValues: 'No results...'
      },
      menu: {
        menu: 'Menu'
      }
    });

    // strings in French
    $translateProvider.translations('FR', {
      searchBox: {
        title: 'STIP Compass Policy Explorer',
        search: 'Chercher',
        page: 'Accueil',
        searchPlaceholder: 'Cherchez dans Omega O.N.E Sight...',
        clearHistory: 'Effacer mon historique',
        searchOptions: 'Options',
        searchMode: 'Mode de recherche',
        datesSelection: 'Filtrer par date',
        precision: 'Précision',
        precisionHelpText:
          '<strong>Precise</strong> : Trouver seulement des objets où les termes de la recherche sont contenus dans le titre ou le résumé.<br><br> <strong>Wide</strong> : Trouver tous les objets identifiés.',
        dateFormat: 'Do MMMM YYYY',
        months: 'Jan,Fev,Mars,Avril,Mai,Juin,Juil,Août,Sept,Oct,Nov,Dec',
        dateFrom: 'A partir de... (jj/mm/aaaa)',
        dateTo: "Jusqu'à... (jj/mm/aaaa)",
        collections: 'Librairies',
        closePanel: 'Fermer',
        resetOptions: 'Réglages par défaut',
        selectAllLibraries: 'Toutes les librairies',
        unselectAllLibraries: 'Aucune librairie',
        apply: 'Mettre à jour',
        reset: 'Réinitialiser',
        welcome: 'Bienvenue dans O.N.E Sight',
        typeBelow: 'Tapez votre recherche ci-dessous :'
      },
      savedResources: {
        title: 'Mes résultats',
        search: 'Chercher un résultat sauvegardé...',
        name: 'Nom',
        actions: 'Actions',
        noResults: "Vous n'avez pas encore sauvegardé de résultats."
      },
      savedSearches: {
        title: 'Mes recherches',
        queryName: 'Nom',
        inputTerms: 'Requête',
        date: 'date',
        actions: 'actions',
        saveSearch: 'Sauvegarder la recherche',
        save: 'Sauvegarder',
        noResults: "Vous n'avez pas encore sauvegardé de recherches."
      },
      results: {
        loading: 'Chargement des résultats...',
        result: 'résultat',
        page: 'page',
        of: 'de',
        for: 'pour',
        withTheConcept: 'avec le concept : ',
        ofResourceType: 'seulement de type : ',
        expand: 'Voir le détail',
        switchViewModeToExpand: "Changer d'affichage pour voir le détail",
        closeDetail: 'Fermer',
        showMore: 'Voir plus de mots-clefs',
        showTags: 'Voir les mots-clefs',
        showLess: 'Voir moins de mots-clefs',
        detail: 'Détail',
        general: 'Géneral',
        title: 'Titre',
        collection: 'Collection',
        link: 'Lien',
        parentLink: 'Lien du parent',
        identifiers: 'Identifiants',
        texts: 'Textes',
        parent: 'Parent',
        structures: 'Structures',
        dates: 'Dates',
        types: 'Types',
        resultsPerPage: 'Résultats par page : ',
        saveResult: 'Sauvegarder ce résultat',
        backToResults: 'Retour aux résultats',
        shareResultByEmail: 'Partager ce résultat par email',
        newItemsSince: 'Nouveaux items depuis',
        send: 'Envoyer',
        emailAddress: 'Adresse email',
        invalidEmailAddress: 'Adresse email invalide!',
        pleaseTypeEmailAddress: "Veuillez taper l'adresse email"
      },
      filters: {
        refineAndDiscoverTools: 'Filtrer et explorer',
        loading: "Chargement des options d'affinage",
        resourceTypes: 'Types',
        conceptTree: 'Identifiés dans votre recherche',
        suggestedConcepts: 'Identifés dans les résultats',
        apply: 'Mettre à jour'
      },
      navigation: {
        previous: 'Précédent',
        next: 'Suivant',
        refineTools: 'Filtrer',
        refineToolsHelpText: 'Affinez vos résultats',
        discoverTools: 'Explorer',
        discoverToolsHelpText: 'Explorez des domaines connexes'
      },
      screenMode: {
        switchViewMode: 'Affichage',
        viewMode: 'Affichage',
        list: 'Liste',
        brickWall: 'Brick Wall',
        whatsNew: 'Nouveautés'
      },
      messages: {
        generalError: "Une erreur s'est produite.",
        resources: {
          stored: 'Le resultat a été sauvegardé',
          updated: 'Le resultat a été mis à jour',
          removed: 'Le resultat a été effacé',
          emailSent: 'Votre email a été envoyé',
          emailFailed: "L'envoi d'un email a échoué"
        },
        searches: {
          stored: 'La recherche a été sauvegardée',
          removed: 'La recherche a été effacée'
        }
      },
      dashboard: {
        title: 'Quoi de neuf sur O.N.E Sight ?',
        datesFrom: {
          oneDay: 'Depuis hier',
          twoDays: 'Depuis avant-hier',
          threeDays: 'Les 3 derniers jours',
          oneWeek: 'La dernière semaine',
          oneMonth: 'Le dernier mois'
        },
        loading: 'Chargement des statistiques...',
        displayResults: 'Afficher les résultats',
        noValues: 'Aucun résultat...'
      },
      menu: {
        menu: 'Menu'
      }
    });
  }
]);
