angular
  .module('omega')
  .service(
    'ResourceService',
    function (
      $rootScope,
      API_BASE_URL,
      $http,
      $state,
      $window,
      $filter,
      SimplifyService,
      GAService
    ) {
      this.list = function (
        terms,
        searchProfileId,
        collections,
        conceptsFlatList,
        page,
        precision,
        dateFrom,
        dateTo,
        refineConcepts,
        unfoundTerms,
        originalConcepts,
        requestedFilter,
        creationDateFrom,
        nonConceptList
      ) {
        // we fire a refined search if we have a concept param
        if (refineConcepts.length) {
          var mode = 'refine';
        } else {
          var mode = null;
          refineConcepts = null;
        }

        var dimensions = {
          dimension4: precision,
          dimension5: searchProfileId,
          metric1:
            (nonConceptList ? Object.keys(nonConceptList).length : 0) + '',
          metric2: originalConcepts ? Object.keys(originalConcepts).length : 0
        };

        if (collections) {
          dimensions.dimension1 = collections
            .reduce(function (acc, item) {
              if (item.label && item.label.EN) {
                acc.push(item.label.EN);
                GAService.event('Search', 'Use Collection', item.label.EN);
              }
              return acc;
            }, [])
            .sort()
            .join('|');
        }

        GAService.event('Search', 'Execute', null, dimensions);
        return $http({
          url: API_BASE_URL + 'basicSearch.xqy',
          method: 'POST',
          data: this.buildFormData(
            terms,
            searchProfileId,
            collections,
            conceptsFlatList,
            page,
            precision,
            dateFrom,
            dateTo,
            mode,
            refineConcepts,
            unfoundTerms,
            null,
            originalConcepts,
            requestedFilter,
            creationDateFrom
          )
        }).then(function (resources) {
          return SimplifyService.simplifyResourceList(resources).then(function (
            simpleResources
          ) {
            return simpleResources;
          });
        });
      };

      // list defined results (one on several) instead of executing a search
      this.listDefined = function (searchProfileId, uris) {
        if (typeof uris === 'string') uris = [uris];

        return $http({
          url: API_BASE_URL + 'basicSearch.xqy',
          method: 'POST',
          data: this.buildFormData(
            '',
            searchProfileId,
            '',
            '',
            1,
            '',
            '',
            '',
            'defined',
            '',
            '',
            uris
          ),
          headers: { 'Content-Type': undefined } // prevent changing content-type
        }).then(function (resources) {
          return SimplifyService.simplifyResourceList(resources).then(function (
            simpleResources
          ) {
            return simpleResources;
          });
        });
      };

      this.show = function (searchProfileId, uri) {
        // we reload state in defined mode
        var params = {
          mode: 'defined',
          uri: uri,
          searchProfileId: searchProfileId
        };
        $window.open(
          $state.href('search', params, { absolute: true }),
          '_blank'
        );
      };

      this.showDetails = function (
        searchProfileId,
        uri,
        refineConcepts,
        flatList
      ) {
        var formData = new FormData();

        formData.append('uri', uri);
        formData.append('searchProfile', searchProfileId);

        // format refine concepts
        if (refineConcepts) {
          var refineConceptUris = [];
          refineConcepts.forEach(function (item) {
            refineConceptUris.push(item.uri);
          });
          refineConceptUris = refineConceptUris.join('|');
        }
        if (refineConceptUris) {
          formData.append('refineMember', refineConceptUris);
        }
        if (typeof flatList !== 'undefined') {
          for (var i = 0; i < flatList.length; i++) {
            formData.append('semanticAndMember', flatList[i].join('|'));
          }
        }

        return $http({
          url: API_BASE_URL + 'getFullDetails.xqy',
          method: 'POST',
          data: formData,
          headers: { 'Content-Type': undefined } // prevent changing content-type
        }).then(function (resource) {
          return SimplifyService.simplifyResourceDetail(resource);
        });
      };

      this.buildFormData = function (
        terms,
        searchProfileId,
        collections,
        conceptsFlatList,
        page,
        precision,
        dateFrom,
        dateTo,
        mode,
        refineConcepts,
        unfoundTerms,
        uris,
        originalConcepts,
        requestedFilter,
        creationDateFrom
      ) {
        dateFrom = typeof dateFrom !== 'undefined' ? dateFrom : null;
        dateTo = typeof dateTo !== 'undefined' ? dateTo : null;
        mode = typeof mode !== 'undefined' ? mode : null;
        unfoundTerms =
          typeof unfoundTerms !== 'undefined' && unfoundTerms.length
            ? unfoundTerms.join(' ')
            : null;
        collections =
          typeof collections !== 'undefined' && collections.length
            ? collections
            : null;
        uris = typeof uris !== 'undefined' ? uris : null;
        requestedFilter =
          typeof requestedFilter !== 'undefined' ? requestedFilter : null;
        originalConcepts =
          typeof originalConcepts !== 'undefined' ? originalConcepts : null;

        var formData = new FormData();

        formData.append('searchProfile', searchProfileId);

        // select and format searchProfile's collections
        var collectionUris = [];
        if (collections) {
          collections.forEach(function (item) {
            if (item.searchProfileId === searchProfileId) {
              collectionUris.push(item.uri);
            }
          });
        }

        formData.append('targetCollections', collectionUris.join('|'));
        formData.append(
          'resultStartsAt',
          1 + (page - 1) * $rootScope.resourcesPerPage
        );
        formData.append('resultEndsAt', page * $rootScope.resourcesPerPage);
        formData.append('precision', precision);
        formData.append('language', $rootScope.lang);

        if (terms) {
          formData.append('textualQuery', terms);
        }
        if (requestedFilter) {
          formData.append('requestedFilters', requestedFilter);
        }
        if (creationDateFrom) {
          formData.append('creationDateFrom', creationDateFrom);
        }

        if (conceptsFlatList && conceptsFlatList.length) {
          for (var i = 0; i < conceptsFlatList.length; i++) {
            if (typeof conceptsFlatList[i] !== 'undefined') {
              formData.append(
                'semanticAndMember',
                conceptsFlatList[i].join('|')
              );
            }
          }
        }

        if (unfoundTerms) {
          formData.append('nonConceptQuery', unfoundTerms);
        }
        if (dateFrom) {
          formData.append('dateFrom', $filter('date')(dateFrom, 'yyyy-MM-dd'));
        }
        if (dateTo) {
          formData.append('dateTo', $filter('date')(dateTo, 'yyyy-MM-dd'));
        }
        if (mode) {
          formData.append('mode', mode);
        }

        // We group refineConcepts by filterId
        if (refineConcepts) {
          var filters = {};
          refineConcepts.forEach(function (item) {
            if (typeof filters[item.filterId] === 'undefined') {
              filters[item.filterId] = [];
            }
            filters[item.filterId].push(item.value);
          });

          angular.forEach(filters, function (values, filter) {
            formData.append(filter, values.join('|'));
          });
        }
        if (originalConcepts) {
          var originalConceptUris = [];
          angular.forEach(originalConcepts, function (originalConcept) {
            originalConceptUris.push(originalConcept.uri);
          });
          if (originalConceptUris.length) {
            formData.append('originalConcepts', originalConceptUris.join('|'));
          }
        }

        if (uris && uris.length) {
          for (var i = 0; i < uris.length; i++) {
            formData.append('resourceURI', uris[i]);
          }
        }

        return formData;
      };
    }
  );
