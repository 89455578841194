app.controller('SearchStorageCtrl', function ($window, $timeout, $filter, $scope, SearchStorageService, FlashMessageService, GAService) {

    var self = this;

    self.store = function (searchData) {
        self.showModal = false;
        SearchStorageService.store(searchData).then(function() {
            FlashMessageService.show($filter('translate')('messages.searches.stored'), 'success');
            GAService.event('Search', 'Save Search Query');
            SearchStorageService.list().then(function (savedSearches) {
                $scope.storage.savedSearches = savedSearches;
            });
        });

        // track Piwik Analytics Goal 3 : Save query
        if (window._paq) window._paq.push(['trackGoal', 3]);
    };

    self.destroy = function (searchData) {
        SearchStorageService.destroy(searchData).then(function () {
            FlashMessageService.show($filter('translate')('messages.searches.removed'), 'success');

            SearchStorageService.list().then(function (savedSearches) {
                $scope.storage.savedSearches = savedSearches;
            });
        })

    };

    self.sendGAEvent = function (action) {
        GAService.event('Search', action);
    };

    self.closeModal = function () {
        self.showModal = false;
    };

});
