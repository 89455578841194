angular.module('omega').service('ResourceStorageService', function (API_BASE_URL, MAX_SAVED_RESOURCES, $http, $q, $rootScope, HelperService, localStorageService) {

    // key for local storage backup storage in case authentication has failed (no username)
    const key = 'savedResources';

    this.list = function () {
        if ($rootScope.username) {
            return $http.get(API_BASE_URL + 'getUserSavedItems.xqy', {params: {'username': $rootScope.username}}).then(function (results) {
                return results.data;
            });
        } else {
            return $q.resolve(localStorageService.get(key));
        }
    };

    this.store = function (rawResource) {
        var resource = {
            username: $rootScope.username,
            id: HelperService.generateId(),
            name: rawResource.name,
            uri: rawResource.uri
        };

        if ($rootScope.username) {
            return $http.post(API_BASE_URL + 'writeUserSavedItem.xqy', resource).then(function () {
                return 'ok';
            });
        } else {
            return this.list().then(function (savedResources) {
                if (savedResources && savedResources.length) {
                    if (savedResources.length >= MAX_SAVED_RESOURCES) {
                        savedResources.shift();
                    }
                    savedResources.push(resource);
                }
                else {
                    savedResources = [resource];
                }

                localStorageService.set(key, savedResources);

                return $q.resolve('ok');
            });
        }
    };

    this.update = function (resource) {
        if ($rootScope.username) {
            return $http.post(API_BASE_URL + 'writeUserSavedItem.xqy', resource).then(function () {
                return 'ok';
            });
        } else {
            return this.list().then(function (savedResources) {
                if (!savedResources.length) return false;

                for (var i = 0; i < savedResources.length; i++) {
                    if (resource.id === savedResources[i].id) {
                        savedResources[i] = resource;
                        localStorageService.set(key, savedResources);
                    }
                }
                return $q.resolve('ok');
            });
        }
    };

    this.destroy = function (id) {
        if ($rootScope.username) {
            return $http.get(API_BASE_URL + 'deleteUserSavedItem.xqy', {
                params: {
                    'username': $rootScope.username,
                    'resultId': id
                }
            }).then(function () {
                return 'ok';
            });
        } else {
            return this.list().then(function (savedResources) {
                for (var i = 0; i < savedResources.length; i++) {
                    if (uri === savedResources[i].uri) {
                        savedResources.splice(i, 1);
                        localStorageService.set(key, savedResources);
                    }
                }
            });
        }
    };

});
