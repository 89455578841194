app.controller('FilterCtrl', function ($scope, $window, MOBILE_BREAKPOINT, GAService) {

    var self = this;

    $scope.$on('initializeSearchData', function () {
        $scope.searchData.updatedRefinedConcepts = angular.copy($scope.searchData.refineConcepts);
        $scope.searchData.filterHasBeenUpdated = false;
    });

    // execute a refined search based on a concept
    self.toggleRefine = function (refineConcept, filterId, applyChanges) {
        applyChanges = typeof applyChanges !== 'undefined' ? applyChanges : false;

        refineConcept.filterId = filterId;

        // if refine concept is already in array we remove it
        if (applyChanges) {
            for (var i = 0; i < $scope.searchData.refineConcepts.length; i++) {
                if ($scope.searchData.refineConcepts[i].value === refineConcept.value) {
                    $scope.searchData.refineConcepts.splice(i, 1);
                    $scope.searchData.updatedRefinedConcepts = angular.copy($scope.searchData.refineConcepts);
                    $scope.searchData.page = 1;
                    return $scope.search($scope.searchData);
                }
            }
        } else {
            for (var i = 0; i < $scope.searchData.updatedRefinedConcepts.length; i++) {
                if ($scope.searchData.updatedRefinedConcepts[i].value === refineConcept.value) {
                    $scope.searchData.filterHasBeenUpdated = true;
                    return $scope.searchData.updatedRefinedConcepts.splice(i, 1);
                }
            }
        }

        if (applyChanges) {
            $scope.searchData.refineConcepts.push(refineConcept);
            $scope.searchData.updatedRefinedConcepts = angular.copy($scope.searchData.refineConcepts);
            $scope.searchData.page = 1;
            $scope.search($scope.searchData);
        } else {
            $scope.searchData.updatedRefinedConcepts.push(refineConcept);
            $scope.searchData.filterHasBeenUpdated = true;
        }
    };

    self.apply = function () {
        $scope.searchData.filterHasBeenUpdated = false;
        $scope.searchData.refineConcepts = angular.copy($scope.searchData.updatedRefinedConcepts);
        $scope.searchData.page = 1;

        if($scope.searchData.refineConcepts) {
            var gabelsByFilterId = $scope.searchData.refineConcepts.reduce(function (acc, item) {
                if (item.filterId) {
                    acc[item.filterId] = acc[item.filterId] || [];
                    acc[item.filterId].push(item.label)
                }
                return acc;
            }, {});
            var gaLabel = Object.keys(gabelsByFilterId).sort().reduce(function (acc, key) {
                acc.push(key + '[' + gabelsByFilterId[key].sort().join('|') + ']');
                return acc;
            }, []).join(';');

            GAService.event('Search', 'Refine', gaLabel);
        }

        $scope.search($scope.searchData);

        // Close sidebar on mobile
        if ($window.innerWidth <= MOBILE_BREAKPOINT) {
            $scope.searchData.showSidebar = false;
        }
    };

    // remove all concepts from one filter and apply changes
    self.clearFilter = function (filterId) {
        for (var i = $scope.searchData.refineConcepts.length - 1; i >= 0; i--) {
            if ($scope.searchData.refineConcepts[i].filterId === filterId) {
                $scope.searchData.refineConcepts.splice(i, 1);
            }
        }
        $scope.searchData.updatedRefinedConcepts = angular.copy($scope.searchData.refineConcepts);
        $scope.searchData.page = 1;
        return $scope.search($scope.searchData);
    };

    self.getFilterRefinedConceptsLength = function (filter) {
        var length = 0;
        for (var i = 0; i < $scope.searchData.refineConcepts.length; i++) {
            if ($scope.searchData.refineConcepts[i].filterId === filter.id) {
                length++;
            }
        }
        return length;
    };

    self.isAtLeastOneFilterActive = function (filters) {
        if (filters && filters.length) {
            for (var i = 0; i < filters.length; i++) {
                if (self.getFilterRefinedConceptsLength(filters[i])) {
                    return true;
                }
            }
        }
        return false;
    };

    self.getIncrementalId = function () {
        if (typeof self.incrementalId === 'undefined')
            self.incrementalId = 0;
        self.incrementalId = self.incrementalId + 1;
        return self.incrementalId;
    };

});
