app.controller('PaginationCtrl', function ($scope, $window) {

    var self = this;

    // Max number of tens links (for each side)
    self.maxTenItems = 5;

    // Build pagination after each search
    $scope.$on('resourceListPromiseSuccessful', function () {
        self.buildPagination();
    });

    angular.element($window).on('resize', function() {
        self.buildPagination();
    });

    self.buildPagination = function () {
        if(!$scope.meta) return;

        var displayedLinks = 5;

        self.pageLinks = [];

        // We display all links if it fits
        if ($scope.meta.pageCount <= displayedLinks) {
            for (var i = 1; i <= $scope.meta.pageCount; i++) {
                self.pageLinks.push(i);
            }
        } else {
            // Google-like smart pagination
            if ($scope.searchData.page <= (displayedLinks / 2) + 1) {
                // Beginning
                var firstPage = 1;
                var lastPage = displayedLinks;
            } else if ($scope.searchData.page + Math.round(displayedLinks / 2) - 1 >= $scope.meta.pageCount) {
                // End
                var firstPage = $scope.meta.pageCount - (displayedLinks - 1);
                var lastPage = $scope.meta.pageCount;
            } else {
                var firstPage = $scope.searchData.page - Math.round(displayedLinks / 2) + 1;
                var lastPage = $scope.searchData.page + Math.round(displayedLinks / 2) - 1;
            }
            for (var i = firstPage; i <= lastPage; i++) {
                self.pageLinks.push(i);
            }
        }

        self.inferiorTens = [];
        var limit = 0;
        for(var i = 1; i < self.pageLinks[0]; i++) {
            if (i % 10 === 0 && limit < self.maxTenItems) {
                self.inferiorTens.push(i);
                limit++;
            }
        }

        self.superiorTens = [];
        var limit = 0;
        for(var i = $scope.meta.pageCount - 1; i > self.pageLinks[self.pageLinks.length - 1]; i--) {
            if (i % 10 === 0 && limit < self.maxTenItems) {
                self.superiorTens.push(i);
                limit++;
            }
        }
    };

    // execute a search for a specific page
    self.goToPage = function (page) {
        if (page == $scope.searchData.page) {
            return false;
        }
        $scope.searchData.page = page;
        $scope.search($scope.searchData);
    };

});
