var app = angular.module('omega', [
  'ui.router',
  'xml',
  'officeuifabric.core',
  'officeuifabric.components',
  'pascalprecht.translate',
  'LocalStorageModule',
  'wu.masonry',
  'chart.js',
  'moment-picker'
]);

/*
 * Events broadcasted
 * "enterBrowserHistoryState" = when user is entering a state previously visited (usually with browser back/next buttons)
 * "initializeSearchData" = when search is initialized or re-initialized
 * "search" = when search is launched by user
 * "searchDataStoredInHistory" = during a search, when we save the query in History
 * "conceptListPromiseSuccessful" = when we have response for concept list on search
 * "resourceListPromiseSuccessful" = when we have response for resources (normal) on search
 * "reCalcViewDimensions" (related to angular-slider package) = we broadcast this event manually to calculate slider dimensions (used for tab panes)
 * "keydown" = fired by "keytrap" directive when user presses a key
 * "settingsReceived" = fired when we receive user settings from API (app.run)
 */

function isIntranetUser() {
  var dfd = $.Deferred();
  if (!__env.intraCheckImgUrl) {
    dfd.resolve(false);
  } else {
    var oImg = document.createElement('img');
    oImg.setAttribute('src', __env.intraCheckImgUrl);
    oImg.onload = () => {
      dfd.resolve(true);
    };
    oImg.onerror = () => {
      dfd.resolve(false);
    };
  }
  return dfd.promise();
}

function urlParam(name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
    window.location.search
  );

  return results !== null ? results[1] || 0 : false;
}

// app settings
$.get('./config.json')
  .then(function (envConfig) {
    if (envConfig) {
      $.extend(__env, envConfig);
    }
  })
  .always(function () {
    angular.element('base').attr('href', __env.directoryUrl);
    app.constant('ENV', __env.environment);
    app.constant('BASE_URL', __env.baseUrl);
    app.constant('HEADER_TYPE', __env.headerType || 'DEFAULT');
    app.constant('GA_TRACKING_ID', __env.gaTrackingId);
    app.constant('EXTRA_ENV_INFO', __env.extraEnvInfo || {});
    app.constant('API_BASE_URL', __env.apiBaseUrl);
    app.constant('IMAGE_SERVER_BASE_URL', __env.imageServerBaseUrl);
    app.constant('DIRECTORY_URL', __env.directoryUrl);
    app.constant('LINK_SERVER_URL', __env.linkServerUrl);
    app.constant('DEFAULT_LANGUAGE', 'EN');
    app.constant('DEFAULT_MASONRY_MODE', false);
    app.constant('DEFAULT_PRECISION', 4);
    app.constant('DEFAULT_SHOW_SIDEBAR', false);
    app.constant('SHOW_DICTIONARY', __env.showDictionary);
    app.constant(
      'GENERIC_DASHBOARD_ACTIVATED',
      __env.genericDashboardActivated
    );
    app.constant('DEFAULT_SEARCH_PROFILE', 'generic');
    app.constant('DEFAULT_DATE_FROM', null);
    app.constant('DEFAULT_DATE_TO', null);
    app.constant('RESOURCES_PER_PAGE', 10);
    app.constant('MAX_SUGGESTED_CONCEPTS', 25);
    app.constant('HISTORY_SEARCHES', 5);
    app.constant('MAX_SAVED_SEARCHES', 50);
    app.constant('MAX_SAVED_RESOURCES', 100);
    app.constant('FLASH_MESSAGES_DURATION', 4000);
    app.constant('MAX_RECOMMENDED_RESULTS', 2000);
    app.constant('MIN_WORD_LENGTH_FOR_SUGGESTIONS', 4);
    app.constant('DISPLAYABLE_TAGS', [
      'http://kim.oecd.org/Taxonomy/Topics#',
      'http://kim.oecd.org/Taxonomy/GeographicalAreas#',
      'http://kim.oecd.org/Taxonomy/Organisations#',
      'http://innovation.oecd.org/taxonomy/topics#'
    ]);
    app.constant('MAX_ITEMS_IN_NON_EXPANDED_CHARTS', 8);
    app.constant('MOBILE_BREAKPOINT', 702);
    app.constant('EMAIL_SENDING_ERROR_STATUS_CODE', 417);
    app.constant('DEFAULT_REFERRER_ID', 'default');

    // app configuration
    app.config(function (
      $stateProvider,
      $httpProvider,
      $locationProvider,
      $urlRouterProvider,
      localStorageServiceProvider
    ) {
      // Routing
      $stateProvider.state('search', {
        url: '/?q?id?mode?uri?searchProfileId?query?referrer',
        templateUrl: 'templates/master.html',
        reloadOnSearch: false,
        controller: 'MainCtrl'
      });

      // Client-side XML to JSON decoder configuration
      $httpProvider.interceptors.push('xmlHttpInterceptor');

      // Default POST requests headers
      $httpProvider.defaults.headers.post['Content-Type'] = undefined;

      // use the HTML5 History API and use html5 mode to remove # in URL
      $locationProvider.html5Mode(true);

      // Send to 404 if unmatched URL
      $urlRouterProvider.otherwise('404');

      // avoid overwriting any local storage variables
      localStorageServiceProvider
        .setPrefix('omega')
        .setStorageType('localStorage');
    });

    // executes on app start up (after config)
    app.run(function (
      $rootScope,
      $state,
      $translate,
      DEFAULT_LANGUAGE,
      RESOURCES_PER_PAGE,
      DIRECTORY_URL,
      GA_TRACKING_ID,
      HEADER_TYPE,
      EXTRA_ENV_INFO,
      LINK_SERVER_URL,
      SettingsService,
      SearchProfileService
    ) {
      function defineSettings() {
        SettingsService.list().then(function (settings) {
          $rootScope.lang =
            settings && settings.lang ? settings.lang : DEFAULT_LANGUAGE;
          $translate.use($rootScope.lang);
          $rootScope.resourcesPerPage =
            settings && settings.resourcesPerPage
              ? settings.resourcesPerPage
              : RESOURCES_PER_PAGE.toString();

          $rootScope.linkServerUrl = LINK_SERVER_URL;
          $rootScope.directoryUrl = DIRECTORY_URL;
          $rootScope.headerType = HEADER_TYPE;
          $rootScope.extraEnvInfo = EXTRA_ENV_INFO;

          $rootScope.settings = settings;

          //check if there is referrer in the url query string, what means the site is referred from another site
          var params = new URL(document.location).searchParams;
          $rootScope.referrer = params.get('referrer');

          //when we have referrer then the settings will come not from the user but from the xml provided by getOmegaConfig.xqy
          if ($rootScope.referrer) {
            SearchProfileService.list().then(function () {
              var referrerSettings = SearchProfileService.referrerSettings(
                $rootScope.referrer
              );
              $rootScope.settings.collections =
                SearchProfileService.getCollections(
                  referrerSettings.targetCollections,
                  referrerSettings.searchProfile
                );
              $rootScope.settings.dateFrom = referrerSettings.dateFrom;
              $rootScope.settings.dateTo = referrerSettings.dateTo;
              $rootScope.settings.precision = referrerSettings.precision;
              $rootScope.settings.searchProfileId =
                referrerSettings.searchProfile;
              //when no user profile and has referrer let's use referrer lang
              $rootScope.lang =
                settings && settings.lang
                  ? settings.lang
                  : referrerSettings.language;
              $rootScope.$broadcast('settingsReceived');
            });
          } else {
            $rootScope.$broadcast('settingsReceived');
          }
        });
      }

      defineSettings();

      /*
       to allow browser back/next buttons navigation (reload search on known locations) we detect when we are in
       a state that we already know and broadcast event.
       */
      var browserHistory = [];
      $rootScope.$on('$locationChangeSuccess', function () {
        $rootScope.$state = $state;

        if (browserHistory.indexOf($state.params.id) !== -1) {
          // been here before
          $rootScope.$broadcast('enterBrowserHistoryState');
        } else {
          browserHistory.push($state.params.id);
        }
      });

      // add GTM to PUBFIN
      if (GA_TRACKING_ID) {
        var head = document.getElementsByTagName('head')[0];
        var gtmScript = document.createElement('script');
        gtmScript.async = true;
        gtmScript.src =
          'https://www.googletagmanager.com/gtag/js?id=' + GA_TRACKING_ID;
        var dataLayerScript = document.createElement('script');

        var gtagConfig = "gtag('js', new Date());";
        gtagConfig += "gtag('config', '" + GA_TRACKING_ID + "');";

        dataLayerScript.innerHTML =
          'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}' +
          gtagConfig;

        head.appendChild(gtmScript);
        head.appendChild(dataLayerScript);
      }
    });

    angular.element(function () {
      angular.bootstrap(document, ['omega']);
    });
  });
