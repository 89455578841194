angular.module('omega').service('SpellCheckService', function (API_BASE_URL, $http) {

    this.suggest = function (term, lang) {
        return $http({
            url: API_BASE_URL + 'spellCheck.xqy',
            method: 'POST',
            data: this.buildFormData(term, lang)
        }).then(function (results) {
            return results.data;
        });
    };

    this.buildFormData = function (term, lang) {
        var formData = new FormData();
        formData.append("term", term);
        if (lang) formData.append("lang", lang);
        return formData;
    };

});

