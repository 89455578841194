angular.module('omega').service('GAService', ['$window', function ($window) {

  this.event = function (category, action, label, dimensions) {
    if ($window.ga) {
      if (action && category) {
        var gaFieldsObject = {
          event_category: category,
        };

        if (label) {
          gaFieldsObject.event_label = label;
        }

        if(dimensions) {
          Object.assign(gaFieldsObject, dimensions)
        }

        console.log('COLLECT', action, gaFieldsObject);
        $window.gtag('event', action, gaFieldsObject);
      }
    }
  }

}]);
