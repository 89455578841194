angular.module('omega').service('ConceptService', function (API_BASE_URL, $http, SimplifyService) {

    this.list = function (terms, searchProfileId, lang) {
        var payload = new FormData();
        if (terms) payload.append("query", terms);
        if (lang) payload.append("lang", lang);
        payload.append("searchProfile", searchProfileId);

        return $http({
            url: API_BASE_URL + 'getConceptsFromQuery.xqy',
            method: 'POST',
            data: payload
        }).then(function (results) {
            return SimplifyService.simplifyConceptList(results);
        });
    };

});