angular
  .module('omega')
  .service(
    'HistoryService',
    function (
      HISTORY_SEARCHES,
      API_BASE_URL,
      $http,
      $q,
      $rootScope,
      localStorageService
    ) {
      // key for local storage backup storage in case authentication has failed (no username)
      const key = 'history';

      this.list = function () {
        if ($rootScope.username) {
          return $http
            .get(API_BASE_URL + 'getUserHistory.xqy', {
              params: { username: $rootScope.username }
            })
            .then(function (results) {
              return results.data;
            });
        } else {
          return $q.resolve(localStorageService.get(key));
        }
      };

      this.get = function (id) {
        if ($rootScope.username) {
          return this.list().then(function (history) {
            if (history && history.length) {
              for (var i = 0; i < history.length; i++) {
                if (id === history[i].id) return history[i];
              }
            }
            return false;
          });
        } else {
          return this.list().then(function (history) {
            if (history && history.length) {
              for (var i = 0; i < history.length; i++) {
                if (id === history[i].id) {
                  return history[i];
                }
              }
            }
            return false;
          });
        }
      };

      this.store = function (searchData) {
        return this.list().then(function (history) {
          // we prevent generating duplicates in history
          if (history && history.length) {
            for (var i = 0; i < history.length; i++) {
              if (
                searchData.searchProfileId == history[i].searchProfileId &&
                searchData.terms == history[i].terms &&
                searchData.filterByDate == history[i].filterByDate &&
                searchData.precision == history[i].precision &&
                JSON.stringify(searchData.concept) ==
                  JSON.stringify(history[i].concept) &&
                JSON.stringify(searchData.refineConcepts) ==
                  JSON.stringify(history[i].refineConcepts)
              ) {
                history.splice(i, 1);
              }
            }
          }
          if (history && history.length) {
            if (history.length >= HISTORY_SEARCHES) history.shift();
            history.push(searchData);
          } else {
            history = [searchData];
          }

          if ($rootScope.username) {
            // add username in all objects (like in settings)
            for (var i = 0; i < history.length; i++) {
              history[i].username = $rootScope.username;
            }

            return $http
              .post(API_BASE_URL + 'writeUserHistory.xqy', history, {
                headers: { 'Content-Type': undefined }
              })
              .then(function (results) {
                return results;
              });
          } else {
            localStorageService.set(key, history);
            return $q.resolve('ok');
          }
        });
      };

      this.reset = function () {
        if ($rootScope.username) {
          return $http
            .get(API_BASE_URL + 'deleteUserHistory.xqy', {
              params: { username: $rootScope.username }
            })
            .then(function (results) {
              return results;
            });
        } else {
          localStorageService.remove(key);
          return $q.resolve('ok');
        }
      };
    }
  );
