angular.module('omega').service('SettingsService', function (API_BASE_URL, DEFAULT_SETTINGS, $http, $q, $rootScope, localStorageService) {

    // key for local storage backup storage in case authentication has failed (no username)
    const key = 'settings';

    this.get = function (property) {
        return this.list().then(function (settings) {
            if (settings && typeof(settings[property]) !== 'undefined') {
                return settings[property];
            }
            return null;
        });
    };

    this.list = function () {
        if ($rootScope.username) {
            return $http.get(API_BASE_URL + 'getUserSettings.xqy', {params: {'username': $rootScope.username}}).then(function (results) {
                return results.data;
            });
        } else {
            return $q.resolve(localStorageService.get(key) || DEFAULT_SETTINGS);
        }
    };

    this.storeProperty = function (property, value) {
        return this.list().then(function (settings) {
            if (!settings) settings = {};
            settings[property] = value;

            if ($rootScope.username) {
                settings.username = $rootScope.username;
                return $http.post(API_BASE_URL + 'writeUserSettings.xqy', settings).then(function (results) {
                    return results;
                });
            } else {
                localStorageService.set(key, settings);
                return $q.resolve('ok');
            }
        });
    };

    this.storeProperties = function (propertyObject) {
        return this.list().then(function (settings) {
            if (!settings) settings = {};

            for (var property in propertyObject) {
                if (propertyObject.hasOwnProperty(property)) {
                    settings[property] = propertyObject[property];
                }
            }

            if ($rootScope.username) {
                settings.username = $rootScope.username;
                return $http.post(API_BASE_URL + 'writeUserSettings.xqy', settings).then(function (results) {
                    return results;
                });
            } else {
                localStorageService.set(key, settings);
                return $q.resolve('ok');
            }

        });
    };

    this.reset = function () {
        if ($rootScope.username) {
            return $http.post(API_BASE_URL + 'writeUserSettings.xqy', {'username': $rootScope.username}).then(function (results) {
                return results;
            });
        } else {
            localStorageService.remove(this.key);
            return $q.resolve('ok');
        }
    };

});
