angular.module('omega').service('EmailService', function ($rootScope, API_BASE_URL, $http, $state, $window, $filter, SimplifyService) {

    //send email to the user himself
    //if there is no email address found on server side then we request the user to provide his email address
    this.sendToMe = function (precision, searchProfileId, semanticAndMembers, language, resourceUri, username) {
        return $http({
            url: API_BASE_URL + 'emailResult.xqy',
            method: 'POST',
            data: this.buildEmailData(precision, searchProfileId, semanticAndMembers, language, resourceUri, username, null),
            headers: {'Content-Type': undefined} // prevent changing content-type
        });
    };
    // send email to the given email address
    this.sendEmail = function (precision, searchProfileId, semanticAndMembers, language, resourceUri, emailAddress) {
        return $http({
            url: API_BASE_URL + 'emailResult.xqy',
            method: 'POST',
            data: this.buildEmailData(precision, searchProfileId, semanticAndMembers, language, resourceUri, null, emailAddress),
            headers: {'Content-Type': undefined} // prevent changing content-type
        });
    };

    //- precision
    //- searchProfile
    //- semanticAndMembers (repeatable)
    //- language ("en","fr")
    //- uri
    //- username (optional, username or email required)
    //- email (optional, username or email required)
    this.buildEmailData = function (precision, searchProfileId, semanticAndMembers, language, resourceUri, username, emailAddress) {

        precision = typeof precision !== 'undefined' ? precision : null;
        searchProfileId = typeof searchProfileId !== 'undefined' ? searchProfileId : null;

        language = typeof language !== 'undefined' ? language : null;
        uri = typeof resourceUri !== 'undefined' ? resourceUri : null;
        semanticAndMembers = typeof semanticAndMembers !== 'undefined' ? semanticAndMembers : null;
        username = typeof username !== 'undefined' ? username : null;
        emailAddress = typeof emailAddress !== 'undefined' ? emailAddress : null;

        var formData = new FormData();

        formData.append("searchProfile", searchProfileId);
        formData.append("precision", precision);


        if (semanticAndMembers) {
            semanticAndMembers.forEach(function (item) {
                formData.append('semanticAndMembers', item);
            });
        }

        formData.append('language', $rootScope.lang);

        formData.append('uri', resourceUri);

        if (emailAddress) {
            formData.append('email', emailAddress);
        } else {
            formData.append('username', username);
        }

        return formData;
    };

});
