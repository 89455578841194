angular.module('omega').service('FlashMessageService', function ($rootScope, $timeout, FLASH_MESSAGES_DURATION) {

    /*
     Displays flash messages where content is the text within the message and type is success|warning|error
     */
    this.show = function (content, type) {
        $rootScope.flashMessage = {
            content: content,
            type: type
        };
        $timeout(this.clear, FLASH_MESSAGES_DURATION);
    };

    this.clear = function () {
        $rootScope.flashMessage = null;
    };

});
