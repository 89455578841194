app.controller('SearchCtrl', function ($scope) {

    /* TODO : To ensure a better separation of concerns, this controller should receive the "search" function.
     * Other functions and preparations to the search and the view should stay in MainCtrl
     */

    var self = this;

    self.searchIfNoDropdown = function(searchData) {
        if ($scope.searchData && !$scope.searchData.suggestions.length && $scope.searchData.terms && $scope.searchData.collections.length) {
            $scope.search(searchData);
        }
    };
});
