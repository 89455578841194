app.controller(
  'CollectionCtrl',
  function ($scope, $rootScope, $state, $filter, SearchProfileService) {
    var self = this;

    // when collections change, we set new filters to request
    $scope.$watchCollection('searchData.collections', function (newCollection) {
      if (typeof newCollection === 'undefined') {
        // initialize searchData in case it's undefined
        $rootScope.$broadcast('settingsReceived');
      }
      if (typeof $rootScope.searchProfiles !== 'undefined') {
        self.updateCheckboxes();
        self.setRequestedFiltersFromCollections();
      }

      if ($scope.searchData) {
        $scope.searchData.collectionsHaveBeenUpdated = true;
      }
    });

    SearchProfileService.list().then(function (searchProfiles) {
      $rootScope.searchProfiles = searchProfiles;

      // if we are not launching historical search
      if (typeof $state.params.id === 'undefined') {
        // we check if user has settings with defined collections
        var userHasDefinedCollections =
          $rootScope.settings &&
          $scope.settings.searchProfileId &&
          $rootScope.settings.collections &&
          $rootScope.settings.collections.length;

        //when we have referrer then values should come from getOmegaConfig.xqy
        if (!$rootScope.referrer) {
          // we set searchProfile and collections, user's or default
          $scope.searchData.searchProfileId = userHasDefinedCollections
            ? $scope.settings.searchProfileId
            : self.getDefaultSelectedSearchProfileId(searchProfiles);
          $scope.searchData.collections = userHasDefinedCollections
            ? $scope.settings.collections
            : self.getDefaultSelectedCollections(searchProfiles);
        }
        // set requested filters if they aren't
        if (typeof $scope.searchData.requestedFilters === 'undefined') {
          self.setRequestedFiltersFromCollections();
        }
      }
    });

    // returns default search profile id as specified with the isDefault prop
    self.getDefaultSelectedSearchProfileId = function (searchProfiles) {
      for (var i = 0; i < searchProfiles.length; i++) {
        if (searchProfiles[i].isDefault) {
          return searchProfiles[i].id;
        }
      }
    };

    // returns default collections object as specified with the isDefault prop
    self.getDefaultSelectedCollections = function (searchProfiles) {
      var selectedCollections = [];

      for (var i = 0; i < searchProfiles.length; i++) {
        if (searchProfiles[i].isDefault) {
          for (var j = 0; j < searchProfiles[i].groups.length; j++) {
            for (
              var k = 0;
              k < searchProfiles[i].groups[j].collections.length;
              k++
            ) {
              if (searchProfiles[i].groups[j].collections[k].isDefault) {
                selectedCollections.push(
                  searchProfiles[i].groups[j].collections[k]
                );
              }
            }
          }
        }
      }
      return selectedCollections;
    };

    // check/uncheck checkboxes and create counts that correspond to current searchData.collections array
    self.updateCheckboxes = function () {
      angular.forEach($rootScope.searchProfiles, function (searchProfile) {
        if (searchProfile.id === $scope.searchData.searchProfileId) {
          angular.forEach(searchProfile.groups, function (group) {
            // we reset collection count to count it again
            group.selectedCollectionCount = 0;

            angular.forEach(group.collections, function (collection) {
              // we select collection if we have it in our searchData.collections object
              if (
                $filter('filter')($scope.searchData.collections, {
                  uri: collection.uri
                }).length
              ) {
                collection.isSelected = true;
                group.selectedCollectionCount++;
              } else {
                collection.isSelected = false;
              }
            });
          });
        }
      });
    };

    // update filters to request on next search based on current selected collections
    self.setRequestedFiltersFromCollections = function () {
      $scope.searchData.requestedFilters = [];
      for (var i = 0; i < $scope.searchData.collections.length; i++) {
        if (typeof $scope.searchData.collections[i].filters !== 'undefined') {
          for (
            var j = 0;
            j < $scope.searchData.collections[i].filters.length;
            j++
          ) {
            if (
              $scope.searchData.requestedFilters.indexOf(
                $scope.searchData.collections[i].filters[j]
              ) === -1
            ) {
              $scope.searchData.requestedFilters.push(
                $scope.searchData.collections[i].filters[j]
              );
            }
          }
        }
      }
    };

    self.unselectAllCollections = function () {
      $scope.searchData.collections = [];
      self.updateCheckboxes();
    };

    self.selectAllCollections = function () {
      var allCollections = [];
      for (var i = 0; i < $rootScope.searchProfiles.length; i++) {
        if (
          $rootScope.searchProfiles[i].id === $scope.searchData.searchProfileId
        ) {
          for (var j = 0; j < $rootScope.searchProfiles[i].groups.length; j++) {
            $rootScope.searchProfiles[i].groups[j].selectedCollectionCount =
              $rootScope.searchProfiles[i].groups[j].collections.length;
            for (
              var k = 0;
              k < $rootScope.searchProfiles[i].groups[j].collections.length;
              k++
            ) {
              allCollections.push(
                $rootScope.searchProfiles[i].groups[j].collections[k]
              );
            }
          }
        }
      }

      $scope.searchData.collections = allCollections;
    };

    // add/remove collection to selection
    self.toggleCollection = function (collection) {
      for (var i = 0; i < $scope.searchData.collections.length; i++) {
        if (collection.uri === $scope.searchData.collections[i].uri) {
          return $scope.searchData.collections.splice(i, 1);
        }
      }
      $scope.searchData.collections.push(collection);
    };

    // add/remove group of collections to selection
    self.toggleGroup = function (group) {
      if (group.selectedCollectionCount > 0) {
        // if group is selected we remove its collections from selection
        for (var i = group.collections.length - 1; i >= 0; i--) {
          for (var j = $scope.searchData.collections.length - 1; j >= 0; j--) {
            // we compare arbitrary with english labels because groups don't have ids
            if (
              $scope.searchData.collections[j].label['EN'] ===
              group.collections[i].label['EN']
            ) {
              $scope.searchData.collections.splice(j, 1);
            }
          }
        }
      } else {
        // else we add its collection to selection
        for (var i = 0; i < group.collections.length; i++) {
          if (
            $scope.searchData.collections.indexOf(group.collections[i]) === -1
          ) {
            $scope.searchData.collections.push(group.collections[i]);
          }
        }
      }
    };

    // prevent checkboxes to uncheck when hiding their tabs
    $scope.$on('reCalcViewDimensions', function () {
      self.updateCheckboxes();
    });

    // on reset options we restore searchProfile and collections as default
    $scope.$on('resetSearchOptions', function () {
      if ($scope.referrer) {
        var referrerSettings = SearchProfileService.referrerSettings(
          $rootScope.referrer
        );
        $rootScope.settings.collections = SearchProfileService.getCollections(
          referrerSettings.targetCollections,
          referrerSettings.searchProfile
        );
        $rootScope.settings.searchProfileId = referrerSettings.searchProfile;
      } else {
        $scope.searchData.searchProfileId =
          self.getDefaultSelectedSearchProfileId($rootScope.searchProfiles);
        $scope.searchData.collections = self.getDefaultSelectedCollections(
          $rootScope.searchProfiles
        );
      }
    });
  }
);
