app.controller('DashboardCtrl', function (DashboardService, $scope, $rootScope, $filter,
                                          DEFAULT_SEARCH_PROFILE, MAX_ITEMS_IN_NON_EXPANDED_CHARTS) {

    var self = this;

    self.params = {
        mode: '1day'
    };
    self.dropdownIsOpen = false;

    self.defaultColor = '#008FFF'; // 'rgba(0,143,255,0.5)'
    self.activeColor = 'rgba(0,143,255,0.5)';
    self.pieColors = ['#8BC741', '#FFC20E', '#008FFF', '#d60a8b'];

    // chart options from http://www.chartjs.org/docs/latest/configuration/tooltip.html?h=tooltip
    self.tooltipOptions = {
        backgroundColor: '#008FFF'
    };

    // chart options from http://www.chartjs.org/docs/latest/general/options.html (use search to find correct docs)
    self.pieChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            display: true,
            position: 'right',
            labels: {
                fontColor: '#33414b' // grey-base
            }
        },
        elements: {
            arc: {
                borderWidth: 6
            }
        },
        tooltips: self.tooltipOptions
    };

    // chart options from http://www.chartjs.org/docs/latest/general/options.html (use search to find correct docs)
    self.barChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    ticks: {autoSkip: false},
                    gridLines: {display: false}
                }],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        userCallback: function (label) {
                            // disable floats
                            if (Math.floor(label) === label) {
                                return label;
                            }

                        }
                    },
                    gridLines: {
                        borderDash: [2, 4],
                        color: '#e4e8ea' // grey-lighter
                    }
                }
            ]
        },
        tooltips: self.tooltipOptions
    };

    self.getDashboard = function () {

        self.loading = true;

        var params = angular.copy(self.params);

        params.language = $rootScope.lang;
        params.searchProfile = $scope.searchData && $scope.searchData.searchProfileId ? $scope.searchData.searchProfileId : DEFAULT_SEARCH_PROFILE;

        DashboardService.get(params).then(function (dashboard) {

            self.loading = false;

            // Build charts (format to angular-chart library)
            angular.forEach(dashboard.aggregates, function (aggregate) {

                aggregate.labels = [];
                aggregate.data = [];
                aggregate.fullLabels = [];
                aggregate.fullData = [];
                aggregate.colors = [];

                angular.forEach(aggregate.values, function (item, index) {

                    // we limit items and label length for readability
                    if (index < MAX_ITEMS_IN_NON_EXPANDED_CHARTS) {
                        // first item (index 0) has less space so we truncate more
                        aggregate.labels.push($filter('truncate')(item.label, true, index === 0 ? 20 : 30));
                        aggregate.data.push(item.count);
                    }
                    aggregate.fullLabels.push(item.label);
                    aggregate.fullData.push(item.count);

                    if (aggregate.chartType === 'barChart') {
                        aggregate.colors.push(item.active ? self.activeColor : self.defaultColor);
                    } else if (aggregate.chartType === 'pie') {
                        aggregate.colors.push(item.active ? self.activeColor : self.pieColors[index]);
                    }
                });

            });

            self.dashboard = dashboard;
        });
    };

    // we fetch data from the server each time we change a param
    $scope.$watch('dashboardVm.params', function () {
        self.getDashboard();
    }, true);

    $scope.$watch('dashboardVm.dropdownIsOpen', function (isOpen) {
        $rootScope.modalActive = isOpen;
    }, true);

    // filter clicking on a chart element to update all values
    self.filter = function (points) {

        if (!points.length) return false;

        // close modal if open
        self.closeExpandedChartModal();

        // find aggregate
        var chartElement = points[0];
        var chartId = chartElement._chart.canvas.dataset.chartId;
        var aggregate = $filter('filter')(self.dashboard.aggregates, {'id': chartId})[0];

        self.params = aggregate.values[chartElement._index].dashboardParams;
    };

    // Execute a search with the params from the dashsboard
    self.displayResults = function (searchParams) {

        $scope.searchData.showDashboard = false;

        // reset page
        $scope.searchData.page = 1;

        $scope.searchData.creationDateFrom = searchParams.creationDateFrom;
        $scope.searchData.precision = searchParams.precision;

        // Collections
        $scope.searchData.searchProfileId = searchParams.searchProfile;
        $scope.searchData.collections = [];

        var collectionUris = searchParams.targetCollections.split('|');
        angular.forEach(collectionUris, function (collectionUri) {
            angular.forEach($rootScope.searchProfiles, function (searchProfile) {
                angular.forEach(searchProfile.groups, function (group) {
                    angular.forEach(group.collections, function (collection) {
                        if (collection.uri === collectionUri) {
                            $scope.searchData.collections.push(collection);
                        }
                    });
                });
            });
        });

        // Concepts
        $scope.searchData.dashboardOriginalConcepts = [];
        if (typeof searchParams.originalConcepts !== 'undefined' && searchParams.originalConcepts) {
            angular.forEach(searchParams.originalConcepts.split('|'), function (originalConceptUri) {
                $scope.searchData.dashboardOriginalConcepts.push({uri: originalConceptUri});
            });
        }

        // convert flatList to correct format
        $scope.searchData.dashboardConceptFlatList = [];
        angular.forEach(searchParams.semanticAndMember, function (concept) {
            // we push an array with the concept as only value
            $scope.searchData.dashboardConceptFlatList.push([concept]);
        });

        // Fire search
        $scope.search($scope.searchData, 'fromDashboard');
    };

    self.expand = function (aggregate) {
        self.showExpandedChart = true;
        self.expandedAggregate = aggregate;
    };

    self.closeExpandedChartModal = function () {
        self.showExpandedChart = false;
    };

});
