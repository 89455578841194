app.controller(
  'ResourceCtrl',
  function (
    $rootScope,
    $scope,
    ResourceService,
    $window,
    $timeout,
    BASE_URL,
    IMAGE_SERVER_BASE_URL,
    MOBILE_BREAKPOINT,
    EmailService,
    FlashMessageService,
    $filter,
    $translate,
    EMAIL_SENDING_ERROR_STATUS_CODE
  ) {
    var self = this;

    self.showModalEmailSend = false; //show or hide the modal window for email address input
    self.showEmailValidation = false;
    self.emailAddress = '';
    self.emailResource;

    self.show = function (resource, searchProfileId) {
      ResourceService.show(searchProfileId, resource.uri);
    };

    self.showDetails = function (resource) {
      if ($scope.searchData.masonryMode) $rootScope.modalActive = true;

      // We are openning a modal in mobile mode and in masonry mode. This var allow us to dynamically add a class to prevent scroll on body
      if (
        $scope.searchData.masonryMode ||
        $window.innerWidth <= MOBILE_BREAKPOINT
      ) {
        $rootScope.modalActive = true;
      }

      // hide others expanded resources
      for (var i = 0; i < $scope.resources.length; i++) {
        $scope.resources[i].expanded = false;
        if ($scope.resources[i].subResources) {
          for (var j = 0; j < $scope.resources[i].subResources.length; j++) {
            $scope.resources[i].subResources[j].expanded = false;
            if ($scope.resources[i].subResources[j].subResources) {
              for (
                var k = 0;
                k < $scope.resources[i].subResources[j].subResources.length;
                k++
              ) {
                $scope.resources[i].subResources[j].subResources[
                  k
                ].expanded = false;
              }
            }
          }
        }
      }
      $scope.loadingDetails = true;

      // on defined mode we don't have concepts so we send and empty element
      if (typeof $scope.concepts === 'undefined')
        $scope.concepts = { flatList: [] };

      ResourceService.showDetails(
        $scope.searchData.searchProfileId,
        resource.uri,
        $scope.searchData.refineConcepts,
        $scope.concepts.flatList
      ).then(function (resourceDetail) {
        $scope.loadingDetails = false;
        $scope.resourceDetail = resourceDetail;
        // track Piwik Analytics Goal 4 : Show Details
        if (window._paq) window._paq.push(['trackGoal', 4]);
      });
    };

    self.switchModeAndShowDetails = function (resource, index) {
      $scope.searchData.masonryMode = false;
      var target = angular.element('#resource-' + index).children()[0];
      // we wait a little bit to build the DOM and get offset
      $timeout(function () {
        angular
          .element('body')
          .animate(
            { scrollTop: target.getBoundingClientRect().top - 100 },
            'slow'
          );
      }, 400);
    };

    self.shareByMail = function (resource) {
      //if there is no email address found on server side then we request the user to provide his email address
      //send email to the user himself
      //TODO later we can fill this semanticAndMembers too, but null is ok for now
      var semanticAndMembers = null;

      EmailService.sendToMe(
        $scope.searchData.precision,
        $scope.searchData.searchProfileId,
        semanticAndMembers,
        $rootScope.lang,
        resource.uri,
        $scope.searchData.username
      ).then(
        function () {
          FlashMessageService.show(
            $filter('translate')('messages.resources.emailSent'),
            'success'
          );
        },
        function (err) {
          //show modal window that ask for the target email address
          //EMAIL_SENDING_ERROR_STATUS_CODE 417
          if (err && err.status == EMAIL_SENDING_ERROR_STATUS_CODE) {
            self.showModalEmailSend = true;
            self.emailResource = resource;
          } else {
            FlashMessageService.show(
              $filter('translate')('messages.resources.emailFailed'),
              'warning'
            );
          }
        }
      );
    };

    //click on email send button
    self.sendEmail = function () {
      self.showModalEmailSend = false;
      //TODO later we can fill this semanticAndMembers too, but null is ok for now
      var semanticAndMembers = null;

      //call email service send email to target
      EmailService.sendEmail(
        $scope.searchData.precision,
        $scope.searchData.searchProfileId,
        semanticAndMembers,
        $rootScope.lang,
        self.emailResource.uri,
        self.emailAddress
      ).then(
        function () {
          FlashMessageService.show(
            $filter('translate')('messages.resources.emailSent'),
            'success'
          );
        },
        function () {
          FlashMessageService.show(
            $filter('translate')('messages.resources.emailFailed'),
            'warning'
          );
        }
      );
    };

    self.closeModalEmailSend = function () {
      self.showModalEmailSend = false;
    };

    // set relevant tags after getting facet and resource promises
    if (typeof $scope.flatConceptList !== 'undefined') {
      for (var i = 0; i < $scope.resources.length; i++) {
        for (var j = 0; j < $scope.resources[i].subResources.length; j++) {
          for (
            var k = 0;
            k < $scope.resources[i].subResources[j].subResources.length;
            k++
          ) {
            // third level
            for (
              var l = 0;
              l <
              $scope.resources[i].subResources[j].subResources[k].tags.length;
              l++
            ) {
              for (var m = 0; m < $scope.flatConceptList.length; m++) {
                if (
                  $scope.resources[i].subResources[j].subResources[k].tags[l]
                    .uri === $scope.flatConceptList[m].uri
                ) {
                  $scope.resources[i].subResources[j].subResources[k].tags[
                    l
                  ].isRelevant = true;
                  break;
                }
              }
            }
          }

          // second level
          for (
            var l = 0;
            l < $scope.resources[i].subResources[j].tags.length;
            l++
          ) {
            for (var m = 0; m < $scope.flatConceptList.length; m++) {
              if (
                $scope.resources[i].subResources[j].tags[l].uri ===
                $scope.flatConceptList[m].uri
              ) {
                $scope.resources[i].subResources[j].tags[l].isRelevant = true;
                break;
              }
            }
          }
        }

        // first level
        for (var j = 0; j < $scope.resources[i].tags.length; j++) {
          for (var k = 0; k < $scope.flatConceptList.length; k++) {
            if (
              $scope.resources[i].tags[j].uri === $scope.flatConceptList[k].uri
            ) {
              $scope.resources[i].tags[j].isRelevant = true;
              break;
            }
          }
        }
      }
    }

    self.getThumbnail = function (resource, size) {
      if (resource.nature !== 'Photo') {
        return './images/no-picture-available.png';
      }

      // Default size is 300px
      size = typeof size !== 'undefined' ? size : 300;

      // we extract resourceId from uri
      var resourceId = resource.uri.substr(resource.uri.indexOf('photos') + 7);

      return IMAGE_SERVER_BASE_URL + size + '/' + resourceId + '.jpg';
    };

    // navigate from one resource to another in Masonry mode
    self.goToResource = function (resourceIndex) {
      $scope.resources[resourceIndex].showDetailModal = true;
    };

    self.closeModal = function (resource) {
      resource.showDetailModal = false;
      $rootScope.modalActive = false;
    };
  }
);
