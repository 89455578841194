angular.module('omega').service('SearchProfileService', function (API_BASE_URL, $rootScope, $http, SimplifyService,DEFAULT_REFERRER_ID) {

    this.list = function () {
        return $http.get(API_BASE_URL + 'getOmegaConfig.xqy')
            .then(function (searchProfiles) {
                // we save searchProfiles and referrerSettings in $rootScope because it's part of config and can be used by other services
                $rootScope.searchProfiles = SimplifyService.simplifySearchProfileList(searchProfiles);
                //in $rootScope.referrerSettings we will have all predefined referrer settings we get from getOmegaConfig.xqy
                $rootScope.referrerSettings = SimplifyService.simplifyRefererSettings(searchProfiles);

                return $rootScope.searchProfiles;
            });
    };

    //when referrer is set in url then instead of user specific or default settings we get the settings from getOmegaConfig.xqy
    this.referrerSettings = function (referrer) {
        if($rootScope.referrerSettings){
            var defaultReferrerSettings;
            var currentReferrerSettings;
            //in $rootScope.referrerSettings we have all predefined referrer settings
            //try to find which id is in the url otherwise use the the one with "default" id
            for (var i = 0; i < $rootScope.referrerSettings.length; i++) {
                if($rootScope.referrerSettings[i]._id === referrer){
                    currentReferrerSettings = $rootScope.referrerSettings[i];
                    break;
                }else if($rootScope.referrerSettings[i]._id === DEFAULT_REFERRER_ID){
                    defaultReferrerSettings = $rootScope.referrerSettings[i];
                }
            }
            return currentReferrerSettings?currentReferrerSettings:defaultReferrerSettings;
        }else{
            //when the method is called before we retrieve from getOmegaConfig.xqy
            throw new Error("RefererSettings not yet retrieved");
        }
    };

    //when referrer is set in url then instead of user specific or default settings we get the settings from getOmegaConfig.xqy
    //in this case we have to build the collection array from url list
    this.getCollections = function (collectionUriList,searchProfileId) {
        if($rootScope.searchProfiles){
            var collections = [];
            var collectionUris = collectionUriList.split('|');
            angular.forEach(collectionUris, function (collectionUri) {
                angular.forEach($rootScope.searchProfiles, function (searchProfile) {
                    if(searchProfile.id === searchProfileId){
                        angular.forEach(searchProfile.groups, function (group) {
                            angular.forEach(group.collections, function (collection) {
                                if (collection.uri === collectionUri) {
                                    collections.push(collection);
                                }
                            });
                        });
                    }
                });
            });
            return collections;
        }else{
            //when the method is called before we retrieve from getOmegaConfig.xqy
            throw new Error("SearchProfiles not yet retrieved");
        }

    }

});