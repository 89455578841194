angular
  .module('omega')
  .service(
    'SimplifyService',
    function (
      DISPLAYABLE_TAGS,
      RESOURCES_PER_PAGE,
      $rootScope,
      LabelService,
      $sce,
      $q,
      $filter
    ) {
      /*
       *   The SimplifyService is an adapter to preserve separation of concerns and assure a clean front-end.
       *
       *   We need to use data in the front-end in a clear way : without depending, knowing and understanding what is the backend logic.
       *   This file is used to convert the data from the backend is a more fluent way for later use in the client-side.
       *   All adaptations should been done ON THIS FILE ONLY to assure a clean code everywhere else.
       *
       */

      // define constants to simplify code : we shouldn't deal with those URIs in front-end
      const CONTEXTUALISED_RESOURCES =
        'http://kim.oecd.org/Model#relevantContextualisedResource';
      const NAME = 'http://kim.oecd.org/Model#tibel';
      const TYPES = 'http://kim.oecd.org/Model#resultType';
      const COLLECTION_URI = 'http://kim.oecd.org/Model#MLCollection';
      const NATURE = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type';
      const LINK = 'http://kim.oecd.org/Model#publicAccessURL';
      const TAGS = 'http://kim.oecd.org/Model#mentions';
      const EXCERPT = 'http://kim.oecd.org/Model#excerpt';
      const ORIGINAL_TEXT = 'http://kim.oecd.org/Fragments/Model#origText';
      const MAIN_TITLE = 'http://kim.oecd.org/Model#mainTitle';
      const ICON = 'http://kim.oecd.org/Model#tibelIconUrl';

      this.simplifySearchProfileList = function (searchProfilesResponse) {
        var simpleSearchProfiles = [];

        // if only one result, XML to JSON converter interprets it as a single value instead of an array. As we need an array in all cases, we convert single value into a single-element array
        var searchProfiles = Array.isArray(
          searchProfilesResponse.data.OmegaConfiguration.SearchProfile
        )
          ? searchProfilesResponse.data.OmegaConfiguration.SearchProfile
          : [searchProfilesResponse.data.OmegaConfiguration.SearchProfile];

        for (var i = 0; i < searchProfiles.length; i++) {
          // same as above, if it's a single value, we convert it into a single-element array
          var rawGroups = Array.isArray(
            searchProfiles[i].SearchableCollections.CollectionGroup
          )
            ? searchProfiles[i].SearchableCollections.CollectionGroup
            : [searchProfiles[i].SearchableCollections.CollectionGroup];

          var simpleGroups = [];
          for (var j = 0; j < rawGroups.length; j++) {
            // same as above, we convert single value into a single-element array
            var rawCollections = Array.isArray(
              rawGroups[j].SearchableCollection
            )
              ? rawGroups[j].SearchableCollection
              : [rawGroups[j].SearchableCollection];

            var simpleCollections = [];
            for (var k = 0; k < rawCollections.length; k++) {
              // we store Collection available Filters into an array
              var filters = [];
              for (
                var l = 0;
                l <
                rawCollections[k]['AvailableFilters']['AvailableFilter'].length;
                l++
              ) {
                filters.push(
                  rawCollections[k]['AvailableFilters']['AvailableFilter'][l][
                    '_id'
                  ]
                );
              }

              // Tag graphs (sub item of Collection not visible in the UI but we use labels in resource details)
              var tagGraphs = [];
              if (
                typeof rawCollections[k]['TagGraphs'] !== 'undefined' &&
                typeof rawCollections[k]['TagGraphs']['TagGraph'] !==
                  'undefined' &&
                typeof rawCollections[k]['TagGraphs']['TagGraph'].length
              ) {
                angular.forEach(
                  rawCollections[k]['TagGraphs']['TagGraph'],
                  function (tagGraph) {
                    tagGraphs.push({
                      uri: tagGraph['_uri'],
                      label: {
                        EN:
                          typeof tagGraph.label !== 'undefined'
                            ? tagGraph.label[0]['__text']
                            : tagGraph['_uri'],
                        FR:
                          typeof tagGraph.label !== 'undefined'
                            ? tagGraph.label[1]['__text']
                            : tagGraph['_uri']
                      }
                    });
                  }
                );
              }

              simpleCollections.push({
                uri: rawCollections[k]['_uri'],
                searchProfileId: searchProfiles[i]['_id'],
                label: {
                  EN: rawCollections[k].label[0]['__text'],
                  FR: rawCollections[k].label[1]['__text']
                },
                isDefault: rawCollections[k]['_default'] === 'true',
                filters: filters,
                tagGraphs: tagGraphs
              });
            }

            simpleGroups.push({
              label: {
                EN: rawGroups[j].label[0]['__text'],
                FR: rawGroups[j].label[1]['__text']
              },
              collections: simpleCollections
            });
          }

          simpleSearchProfiles.push({
            id: searchProfiles[i]['_id'],
            name: searchProfiles[i]['_name'],
            description: {
              EN: searchProfiles[i]['comment'][0]['__text'],
              FR: searchProfiles[i]['comment'][1]['__text']
            },
            isDefault: searchProfiles[i]['_default'] === 'true',
            groups: simpleGroups,
            hasDashboard: searchProfiles[i]['GenericDashboard'] === 'true'
          });
        }

        return simpleSearchProfiles;
      };

      this.simplifyRefererSettings = function (omegaConfigResponse) {
        var simpleRefererSettings =
          omegaConfigResponse.data.OmegaConfiguration.Referrers.Referrer;
        return simpleRefererSettings;
      };

      this.simplifyConceptList = function (concepts) {
        var simpleConcepts = {
          flatList: [],
          unfoundTerms: [],
          originalConcepts: [],
          structuredList: concepts.data.structuredList,
          originalResponse: JSON.stringify(concepts) // we keep a copy of exact response to send to server
        };

        // If list has one element, we receive a string instead of an array, we have to convert it into a one-element array
        angular.forEach(concepts.data.flatList, function (stringOrArray) {
          var conceptGroup =
            typeof stringOrArray === 'string' ? [stringOrArray] : stringOrArray;
          simpleConcepts.flatList.push(conceptGroup);
        });

        // we build an array with terms that are not identified by API as a Concept
        for (var property in concepts.data.nonConceptList) {
          if (concepts.data.nonConceptList.hasOwnProperty(property)) {
            for (
              var i = 0;
              i < concepts.data.nonConceptList[property].length;
              i++
            ) {
              simpleConcepts.unfoundTerms.push(
                concepts.data.nonConceptList[property][i]
              );
            }
          }
        }

        for (var property in concepts.data.originalList) {
          if (concepts.data.originalList.hasOwnProperty(property)) {
            for (
              var i = 0;
              i < concepts.data.originalList[property].length;
              i++
            ) {
              simpleConcepts.originalConcepts.push({
                // we don't get a Label for this property because we don't display this data at the moment
                uri: concepts.data.originalList[property][i]
              });
            }
          }
        }

        return simpleConcepts;
      };

      this.simplifyResourceList = function (resources) {
        var simpleResources = {
          data: [],
          meta: {
            resultCount: resources.data.totalOfResults,
            pageCount: Math.ceil(
              resources.data.totalOfResults / $rootScope.resourcesPerPage
            )
          }
        };

        // check if tag uri belongs to set of defined tags that we can display
        function isTagDisplayable(tagUri) {
          for (var i = 0; i < DISPLAYABLE_TAGS.length; i++) {
            if (tagUri.indexOf(DISPLAYABLE_TAGS[i]) !== -1) return true;
          }
          return false;
        }

        var promises = [];

        // we create a 3 level deep array with the data that we will use for display
        angular.forEach(resources.data.results, function (resultObject) {
          for (var key in resultObject) {
            (function (key) {
              if (resultObject.hasOwnProperty(key)) {
                var resource = resultObject[key];
                var subResources = [];

                if (resource.hasOwnProperty(CONTEXTUALISED_RESOURCES)) {
                  for (var prop in resource[CONTEXTUALISED_RESOURCES]) {
                    var subPromises = [];

                    for (var subKey in resource[CONTEXTUALISED_RESOURCES][
                      prop
                    ]) {
                      (function (subKey) {
                        var subResource =
                          resource[CONTEXTUALISED_RESOURCES][prop][subKey];
                        var subSubResources = [];
                        var subSubPromises = [];

                        if (
                          subResource.hasOwnProperty(CONTEXTUALISED_RESOURCES)
                        ) {
                          for (var subProp in resource[
                            CONTEXTUALISED_RESOURCES
                          ]) {
                            for (var subSubKey in subResource[
                              CONTEXTUALISED_RESOURCES
                            ][subProp]) {
                              (function (subSubKey) {
                                var subSubResource =
                                  subResource[CONTEXTUALISED_RESOURCES][
                                    subProp
                                  ][subSubKey];

                                var displayableTags = [];
                                if (
                                  typeof subSubResource[TAGS] != 'undefined'
                                ) {
                                  for (
                                    var i = 0;
                                    i < subSubResource[TAGS].length;
                                    i++
                                  ) {
                                    if (
                                      isTagDisplayable(subSubResource[TAGS][i])
                                    ) {
                                      displayableTags.push(
                                        subSubResource[TAGS][i]
                                      );
                                    }
                                  }
                                }

                                var tagLabelsPromise =
                                  LabelService.list(displayableTags);
                                var natureLabelPromise = LabelService.get(
                                  subSubResource[NATURE]
                                );

                                subSubPromises.push(
                                  $q
                                    .all([tagLabelsPromise, natureLabelPromise])
                                    .then(function (results) {
                                      var nature = results[1].data;

                                      var tags = [];
                                      for (
                                        var i = 0;
                                        i < results[0].length;
                                        i++
                                      ) {
                                        tags.push({
                                          label: results[0][i].data
                                        });
                                      }

                                      subSubResources.push({
                                        name: subSubResource[NAME],
                                        types: subSubResource[TYPES],
                                        nature: nature,
                                        link: subSubResource[LINK],
                                        excerpt: subSubResource[EXCERPT],
                                        tags: tags,
                                        uri: subSubKey
                                      });
                                    })
                                );
                              })(subSubKey);
                            }
                          }
                        }

                        $q.all(subSubPromises).then(function () {
                          var displayableTags = [];
                          if (typeof subResource[TAGS] !== 'undefined') {
                            for (var i = 0; i < subResource[TAGS].length; i++) {
                              if (isTagDisplayable(subResource[TAGS][i])) {
                                displayableTags.push(subResource[TAGS][i]);
                              }
                            }
                          }

                          var tagLabelsPromise =
                            LabelService.list(displayableTags);
                          var natureLabelPromise = LabelService.get(
                            subResource[NATURE]
                          );

                          subPromises.push(
                            $q
                              .all([tagLabelsPromise, natureLabelPromise])
                              .then(function (results) {
                                var nature = results[1].data;

                                var tags = [];
                                for (var i = 0; i < results[0].length; i++) {
                                  tags.push({ label: results[0][i].data });
                                }
                                subResources.push({
                                  name: subResource[NAME],
                                  types: subResource[TYPES],
                                  nature: nature,
                                  subResources: subSubResources,
                                  link: subResource[LINK],
                                  excerpt: subResource[EXCERPT],
                                  tags: tags,
                                  uri: subKey
                                });
                              })
                          );
                        });
                      })(subKey);
                    }
                  }
                }

                $q.all(subPromises).then(function () {
                  var displayableTags = [];
                  if (typeof resource[TAGS] !== 'undefined') {
                    for (var i = 0; i < resource[TAGS].length; i++) {
                      if (isTagDisplayable(resource[TAGS][i])) {
                        displayableTags.push(resource[TAGS][i]);
                      }
                    }
                  }
                  var tagLabelsPromise = LabelService.list(displayableTags);
                  var natureLabelPromise = LabelService.get(resource[NATURE]);

                  promises.push(
                    $q
                      .all([tagLabelsPromise, natureLabelPromise])
                      .then(function (results) {
                        var nature = results[1].data;

                        var tags = [];
                        for (var i = 0; i < results[0].length; i++) {
                          tags.push({ label: results[0][i].data });
                        }

                        simpleResources.data.push({
                          name: resource[NAME],
                          icon: resource[ICON],
                          types: resource[TYPES],
                          collection: LabelService.getCollectionNameFromUri(
                            resource[COLLECTION_URI]
                          ),
                          nature: nature,
                          subResources: subResources,
                          link: resource[LINK],
                          excerpt: resource[EXCERPT],
                          tags: tags,
                          uri: key // uri if internal document (for debug purposes)
                        });
                      })
                  );
                });
              }
            })(key);
          }
        });

        return $q.all(promises).then(function () {
          return simpleResources;
        });
      };

      this.simplifyResourceDetail = function (resource) {
        var promises = [];
        var simpleResource = {};

        // simple values
        simpleResource.collection = LabelService.getCollectionNameFromUri(
          resource.data.collection
        );
        simpleResource.uri = resource.data.publicAccessURL;
        simpleResource.parentUri = resource.data.parentPublicAccessURL;
        simpleResource.fieldGroups = resource.data.fieldGroups;

        simpleResource.fieldGroups.forEach((fieldGroup) => {
          fieldGroup.fields.forEach((field) => {
            if (field.isHTML) {
              field.values.forEach((value) => {
                value.html = $sce.trustAsHtml(value.text);
              });
            }
          });
        });

        promises.push(
          LabelService.get(resource.data.primaryType).then(function (results) {
            simpleResource.primaryType = results.data;
          })
        );
        promises.push(
          LabelService.get(resource.data.searchProfile).then(function (
            results
          ) {
            simpleResource.searchProfile = results.data;
          })
        );

        // texts
        simpleResource.texts = [];
        if (typeof resource.data.texts !== 'undefined') {
          for (var property in resource.data.texts) {
            (function (property) {
              if (resource.data.texts.hasOwnProperty(property)) {
                promises.push(
                  LabelService.get(property).then(function (results) {
                    simpleResource.texts.push({
                      label: results.data,
                      value: $sce.trustAsHtml(
                        resource.data.texts[property].join('<br>')
                      )
                    });
                  })
                );
              }
            })(property);
          }
        }

        // dates
        simpleResource.dates = [];
        if (typeof resource.data.dates !== 'undefined') {
          for (var property in resource.data.dates) {
            (function (property) {
              if (resource.data.dates.hasOwnProperty(property)) {
                var dateString = resource.data.dates[property][0];

                // if matches date format YYYY-MM-DDZ we remove the "Z" to make the date filter work
                if (dateString.match('^\\d{4}([-])\\d{2}\\1\\d{2}[Z]$')) {
                  dateString = $filter('date')(dateString.slice(0, -1));
                } else {
                  dateString = $filter('date')(dateString);
                }

                promises.push(
                  LabelService.get(property).then(function (results) {
                    simpleResource.dates.push({
                      label: results.data,
                      value: dateString
                    });
                  })
                );
              }
            })(property);
          }
        }

        // structures
        simpleResource.structures = [];
        if (typeof resource.data.structures !== 'undefined') {
          for (var property in resource.data.structures) {
            if (resource.data.structures.hasOwnProperty(property)) {
              (function (property) {
                var resources = [];
                var structurePromises = [];
                for (
                  var i = 0;
                  i < resource.data.structures[property].length;
                  i++
                ) {
                  structurePromises.push(
                    LabelService.get(
                      resource.data.structures[property][i].primaryType
                    )
                  );
                }
                $q.all(structurePromises).then(function (results) {
                  for (var i = 0; i < results.length; i++) {
                    resources.push({
                      title: resource.data.structures[property][i].tibel,
                      uri: resource.data.structures[property][i].uri,
                      clickable:
                        resource.data.structures[property][i].rebounceable,
                      nature: results[i].data
                    });
                  }

                  promises.push(
                    LabelService.get(property).then(function (results) {
                      simpleResource.structures.push({
                        label: results.data,
                        resources: resources
                      });
                    })
                  );
                });
              })(property);
            }
          }
        }

        // types
        simpleResource.types = [];
        if (typeof resource.data.types !== 'undefined') {
          for (var property in resource.data.types) {
            if (resource.data.types.hasOwnProperty(property)) {
              (function (property) {
                var typePromises = [];
                for (var i = 0; i < resource.data.types[property].length; i++) {
                  typePromises.push(
                    LabelService.get(resource.data.types[property][i])
                  );
                }
                $q.all(typePromises).then(function (results) {
                  var values = [];
                  for (var i = 0; i < results.length; i++) {
                    values.push(results[i].data);
                  }
                  promises.push(
                    LabelService.get(property).then(function (results) {
                      simpleResource.types.push({
                        label: results.data,
                        value: $sce.trustAsHtml(values.join('<br>'))
                      });
                    })
                  );
                });
              })(property);
            }
          }
        }

        // identifiers
        simpleResource.identifiers = [];
        if (typeof resource.data.identifiers !== 'undefined') {
          for (var property in resource.data.identifiers) {
            if (resource.data.identifiers.hasOwnProperty(property)) {
              (function (property) {
                promises.push(
                  LabelService.get(property).then(function (results) {
                    simpleResource.identifiers.push({
                      label: results.data,
                      value: $sce.trustAsHtml(
                        resource.data.identifiers[property].join('<br>')
                      )
                    });
                  })
                );
              })(property);
            }
          }
        }

        // parent
        simpleResource.parentInformations = [];
        if (typeof resource.data.parent !== 'undefined') {
          for (var property in resource.data.parent) {
            if (resource.data.parent.hasOwnProperty(property)) {
              (function (property) {
                promises.push(
                  LabelService.get(property).then(function (results) {
                    simpleResource.parentInformations.push({
                      label: results.data,
                      value: $sce.trustAsHtml(
                        resource.data.parent[property].join('<br>')
                      )
                    });
                  })
                );
              })(property);
            }
          }
        }

        return $q.all(promises).then(function () {
          return simpleResource;
        });
      };
    }
  );
