angular.module('omega').service('DebounceService', function($timeout, $q) {
    function debounce(func, wait, immediate) {
        let timeout;
        let deferred = $q.defer();
        return function() {
            let context = this, args = arguments;
            let later = function() {
                timeout = null;
                if(!immediate) {
                    deferred.resolve(func.apply(context, args));
                    deferred = $q.defer();
                }
            };
            let callNow = immediate && !timeout;
            if ( timeout ) {
                $timeout.cancel(timeout);
            }
            timeout = $timeout(later, wait);
            if (callNow) {
                deferred.resolve(func.apply(context,args));
                deferred = $q.defer();
            }
            return deferred.promise;
        };
    }

    function debounceReduce(func, combine, wait) {
        let allargs,
          context,
          wrapper = debounce(function() {
              let args = allargs;
              allargs = undefined;
              func.apply(context, args);
          }, wait);
        return function() {
            context = this;
            allargs = combine.apply(context, [allargs,  Array.prototype.slice.call(arguments,0)]);
            wrapper();
        };
    }

    this.debounce = debounce;
    this.debounceReduce = debounceReduce;

});
