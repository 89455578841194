angular.module('omega').service('DashboardService', function (API_BASE_URL, $http) {

    this.get = function (params) {
        return $http.post(API_BASE_URL + 'genericDashboard.xqy', this.buildFormData(params)).then(function (results) {
            return results.data;
        });
    };

    this.buildFormData = function (params) {

        var formData = new FormData();

        if (params.language) {
            formData.append("language", params.language);
        }

        if (params.mode) {
            formData.append("mode", params.mode);
        }

        if (params.precision) {
            formData.append("precision", params.precision);
        }

        if (params.searchProfile) {
            formData.append("searchProfile", params.searchProfile);
        }

        if (params.conceptFilters) {
            formData.append("conceptFilters", params.conceptFilters.join('|'));
        }

        if (params.collectionFilter) {
            formData.append("collectionFilter", params.collectionFilter);
        }

        return formData;
    };

});
