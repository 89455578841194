app.controller('HistoryCtrl', function ($scope, $rootScope, HistoryService) {

    var self = this;

    // we need username to get the History on load so if we have it we fire the query, else we wait for settings received
    if ($rootScope.username) {
        HistoryService.list().then(function (history) {
            self.history = history;
        });
    }

    $scope.$on('settingsReceived', function () {
        HistoryService.list().then(function (history) {
            self.history = history;
        });
    });

    self.focusIndex = 0;
    self.keys = [
        {
            // enter
            code: 13,
            action: function () {
                $scope.search(self.history[(self.history.length - 1) - self.focusIndex]);
            }
        },
        {
            // up arrow
            code: 38,
            action: function () {
                if (self.focusIndex > 0) self.focusIndex--;
            }
        },
        {
            // down arrow
            code: 40,
            action: function () {
                if (self.focusIndex < 4) self.focusIndex++;
            }
        }
    ];

    self.clear = function () {
        HistoryService.reset().then(function () {
            self.history = [];
        });
    };

    // refresh history when new element is stored
    $scope.$on('searchDataStoredInHistory', function () {
        HistoryService.list().then(function (history) {
            self.history = history;
        });
    });

    $scope.$on('keydown', function (msg, obj) {
        if ($scope.searchData.showHistory && self.history && self.history.length && !$scope.searchData.terms) {
            var code = obj.code;
            self.keys.forEach(function (o) {
                if (o.code !== code) {
                    return;
                }
                o.action();
                $scope.$apply();
            });
        }
    });

});
