app.directive('ngEnter', function () {
  return function (scope, element, attrs) {
    element.bind('keydown keypress', function (event) {
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEnter);
        });

        event.preventDefault();
      }
    });
  };
});

app.directive('ngSpace', function () {
  return function (scope, element, attrs) {
    element.bind('keydown keypress', function (event) {
      if (event.which === 32) {
        scope.$apply(function () {
          scope.$eval(attrs.ngSpace);
        });
      }
    });
  };
});

app.filter('truncate', function () {
  return function (value, wordwise, max, tail) {
    if (!value) return '';

    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');
      if (lastspace !== -1) {
        //Also remove . and , so its gives a cleaner result.
        if (
          value.charAt(lastspace - 1) === '.' ||
          value.charAt(lastspace - 1) === ','
        ) {
          lastspace = lastspace - 1;
        }
        value = value.substr(0, lastspace);
      }
    }

    return value + (tail || ' …');
  };
});

// executes operation on focus
app.directive('onFocus', function () {
  return {
    restrict: 'A',
    link: function (scope, elm, attrs) {
      elm.bind('focus', function () {
        scope.$apply(attrs.onFocus);
      });
    }
  };
});

// executes operation on blur
app.directive('onBlur', function () {
  return {
    restrict: 'A',
    link: function (scope, elm, attrs) {
      elm.bind('blur', function () {
        scope.$apply(attrs.onBlur);
      });
    }
  };
});

app.directive('clickOut', [
  '$window',
  '$parse',
  function ($window, $parse) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var clickOutHandler = $parse(attrs.clickOut);
        angular.element($window).on('click', function (event) {
          /* do not trigger action if is datepicker modal (not really nice code but
           * moment-datepicker module elements don't seems to have a common ancestor
           */
          if (
            event.target.className === 'ng-binding ng-scope' ||
            event.target.className === 'ng-binding ng-scope selected' ||
            event.target.className === 'ng-binding' ||
            event.target.className === 'ng-binding ng-scope disabled'
          ) {
            return;
          }

          if (element[0].contains(event.target)) return;
          clickOutHandler(scope, { $event: event });
          scope.$apply();
        });
      }
    };
  }
]);

app.directive('updateTitle', [
  '$rootScope',
  '$timeout',
  '$state',
  function ($rootScope, $timeout, $state) {
    return {
      link: function () {
        var listener = function () {
          $timeout(function () {
            if (
              typeof $state.params !== 'undefined' &&
              typeof $state.params.q !== 'undefined'
            ) {
              // search mode title
              $rootScope.title =
                $state.params.q + ' - STIP Compass Policy Explorer';
            } else if (
              typeof $state.params !== 'undefined' &&
              typeof $state.params.mode !== 'undefined' &&
              $state.params.mode === 'defined'
            ) {
              // defined mode title
              $rootScope.title =
                $state.params.uri + ' - STIP Compass Policy Explorer';
            } else {
              // default title
              $rootScope.title = 'STIP Compass Policy Explorer';
            }
          });
        };
        $rootScope.$on('$locationChangeSuccess', listener);
      }
    };
  }
]);

// broadcasts event on pressing a key if focus on element
app.directive('keyTrap', function () {
  return function (scope, elem) {
    elem.bind('keydown', function (event) {
      // we prevent default if key is arrow up / arrow down / enter
      if (
        event.keyCode === 40 ||
        event.keyCode === 38 ||
        event.keyCode === 13
      ) {
        event.preventDefault();
      }
      scope.$broadcast('keydown', { code: event.keyCode });
    });
  };
});

app.directive('appHeader', [
  '$rootScope',
  function ($rootScope) {
    return {
      templateUrl: 'templates/partials/header.html',
      scope: true,
      restrict: 'E',
      controller: ['$scope', function AppHeaderCtrl($scope) {}]
    };
  }
]);

app.directive('gaEvent', [
  'GAService',
  '$window',
  function (GAService, $window) {
    return {
      link: function (scope, elem, attrs) {
        if (!$window.ga) return;
        if (!attrs.gaEvent) return;

        elem.on('click', function () {
          try {
            var shouldCollect = attrs.gaEventIf
              ? scope.$eval(attrs.gaEventIf)
              : true;
            if (!shouldCollect) return;

            var gaEvent = scope.$eval('(' + attrs.gaEvent + ')');
            var dimensions = gaEvent.custom || {};
            if (gaEvent.value) {
              dimensions.value = gaEvent.value;
            }
            GAService.event(
              gaEvent.category,
              gaEvent.action,
              gaEvent.label,
              dimensions
            );
          } catch (err) {
            console.warn(err);
          }
        });
      }
    };
  }
]);

// Displays items in reverse order
app.filter('reverse', function () {
  return function (items) {
    if (!items || !items.length) {
      return [];
    }
    return items.slice().reverse();
  };
});
