app.controller('SuggestionCtrl', function ($scope, SpellCheckService, MIN_WORD_LENGTH_FOR_SUGGESTIONS, SHOW_DICTIONARY) {

    var self = this;


    self.focusIndex = 0;
    self.lastWordLang = null;

    // pass env data to view
    self.showDictionary = SHOW_DICTIONARY;

    self.keys = [
        {
            code: 13,
            action: function () {
                self.replace($scope.searchData.suggestions[self.focusIndex]);
            }
        },
        {
            code: 38,
            action: function () {
                if (self.focusIndex > 0) {
                    self.focusIndex--;
                } else {
                    $scope.searchData.suggestions = [];
                    self.focusIndex = 0;
                }
            }
        },
        {
            code: 40,
            action: function () {
                if (self.focusIndex < $scope.searchData.suggestions.length - 1) self.focusIndex++;
            }
        }
    ];

    self.replace = function (suggestion) {
        var terms = $scope.searchData.terms.split(' ');
        terms.splice(-1, 1);
        $scope.searchData.terms = terms.join(' ') + (terms.length ? ' ' : '') + suggestion.value + ' ';
        $scope.searchData.suggestions = [];
        $scope.searchData.lang = self.lastWordLang;
        self.focusIndex = 0;
    };

    self.suggest = function (terms) {

        if (SHOW_DICTIONARY) {
            // if no terms we show history
            if (!terms || terms.length <= 1) {
                self.lastWordLang = null;
                $scope.searchData.lang = null;
                $scope.searchData.showHistory = true;
                return;
            }

            self.modifiedLastTerm = null;

            // we get the last word
            var termsArray = terms.split(' ');
            var term = termsArray[termsArray.length - 1];

            // we don't call the service for small words
            if (term.length < MIN_WORD_LENGTH_FOR_SUGGESTIONS) return $scope.searchData.suggestions = [];

            SpellCheckService.suggest(term, $scope.searchData.lang).then(function (suggestions) {

                $scope.searchData.suggestions = suggestions;

                self.focusIndex = 0;

                for (var i = 0; i < suggestions.length; i++) {
                    if (suggestions[i].match) {
                        if (suggestions[i].langs.indexOf('fr') !== -1 && suggestions[i].langs.indexOf('en') === -1) {
                            self.lastWordLang = 'fr';
                            self.modifiedLastTerm = suggestions[i].value;
                        } else if (suggestions[i].langs.indexOf('en') !== -1 && suggestions[i].langs.indexOf('fr') === -1) {
                            self.lastWordLang = 'en';
                            self.modifiedLastTerm = suggestions[i].value;
                        }
                    }
                }
            });
        }

    };

    self.validateWord = function () {
        if (SHOW_DICTIONARY) {
            $scope.searchData.suggestions = [];
            $scope.searchData.lang = self.lastWordLang;
            var termsArray = $scope.searchData.terms.split(' ');
            if (self.modifiedLastTerm) {
                termsArray.splice(-1, 1);
                $scope.searchData.terms = termsArray.join(' ') + (termsArray.length ? ' ' : '') + self.modifiedLastTerm;
            }
        }
    };

    $scope.$on('keydown', function (msg, obj) {
        if (SHOW_DICTIONARY && $scope.searchData.suggestions && $scope.searchData.suggestions.length) {
            var code = obj.code;
            self.keys.forEach(function (o) {
                if (o.code !== code) {
                    return;
                }
                o.action();
                $scope.$apply();
            });
        }
    });

});
