angular
  .module('omega')
  .service(
    'LabelService',
    function (API_BASE_URL, $rootScope, $http, $q, DebounceService) {
      let cachedPromises = {};
      const debouncedFetch = DebounceService.debounceReduce(
        function () {
          $http
            .post(
              API_BASE_URL + 'URI-to-mainLabel-BlackBox.xqy',
              JSON.stringify({ mainLabelRequests: [].slice.call(arguments) })
            )
            .then(function (response) {
              response.data.forEach(function (item) {
                cachedPromises[item.uri + item.lang].resolve({
                  data: item.label
                });
              });
            });
        },
        function (acc, args) {
          return (acc || []).concat(args);
        },
        1000
      );

      // returns a promise of the request to get a label from a URI
      this.get = function (uri, lang) {
        lang = typeof lang !== 'undefined' ? lang : $rootScope.lang;
        lang = lang.toLowerCase();

        if (typeof cachedPromises[uri + lang] !== 'undefined')
          return cachedPromises[uri + lang].promise;

        // Warning : We are building a URL that has its parameters included in a orthodox way. API should accept classic encoded params to be more stable.
        cachedPromises[uri + lang] = $q.defer();
        debouncedFetch({ uri: uri, lang: lang });
        return cachedPromises[uri + lang].promise;
      };

      this.getCollectionNameFromUri = function (collectionUri) {
        if (
          typeof $rootScope.searchProfiles &&
          $rootScope.searchProfiles.length
        ) {
          for (var i = 0; i < $rootScope.searchProfiles.length; i++) {
            if ($rootScope.searchProfiles[i].hasOwnProperty('groups')) {
              for (
                var j = 0;
                j < $rootScope.searchProfiles[i].groups.length;
                j++
              ) {
                if (
                  $rootScope.searchProfiles[i].groups[j].hasOwnProperty(
                    'collections'
                  )
                ) {
                  for (
                    var k = 0;
                    k <
                    $rootScope.searchProfiles[i].groups[j].collections.length;
                    k++
                  ) {
                    if (
                      collectionUri ===
                      $rootScope.searchProfiles[i].groups[j].collections[k].uri
                    ) {
                      return $rootScope.searchProfiles[i].groups[j].collections[
                        k
                      ].label;
                    }
                    // Search into tag graphs
                    if (
                      typeof $rootScope.searchProfiles[i].groups[j].collections[
                        k
                      ]['tagGraphs'] !== 'undefined' &&
                      $rootScope.searchProfiles[i].groups[j].collections[k][
                        'tagGraphs'
                      ].length
                    ) {
                      for (
                        var l = 0;
                        l <
                        $rootScope.searchProfiles[i].groups[j].collections[k][
                          'tagGraphs'
                        ].length;
                        l++
                      ) {
                        if (
                          collectionUri ===
                            $rootScope.searchProfiles[i].groups[j].collections[
                              k
                            ]['tagGraphs'][l].uri &&
                          typeof $rootScope.searchProfiles[i].groups[j]
                            .collections[k]['tagGraphs'][l].label !==
                            'undefined'
                        ) {
                          return $rootScope.searchProfiles[i].groups[j]
                            .collections[k]['tagGraphs'][l].label;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        // Return URI for both languages if we can't search
        return {
          EN: collectionUri,
          FR: collectionUri
        };
      };

      // Returns an array of deferred promises
      this.list = function (uris) {
        var promises = [];

        if (typeof uris !== 'undefined' && uris.length) {
          for (var i = 0; i < uris.length; i++) {
            promises.push(this.get(uris[i]));
          }
        }
        return $q.all(promises);
      };
    }
  );
