app.controller(
  'MainCtrl',
  function (
    DEFAULT_SEARCH_PROFILE,
    DEFAULT_SHOW_SIDEBAR,
    DEFAULT_PRECISION,
    DEFAULT_DATE_FROM,
    DEFAULT_DATE_TO,
    MAX_RECOMMENDED_RESULTS,
    DEFAULT_MASONRY_MODE,
    MOBILE_BREAKPOINT,
    GENERIC_DASHBOARD_ACTIVATED,
    $rootScope,
    $scope,
    $q,
    $state,
    $window,
    $filter,
    $timeout,
    ConceptService,
    ResourceService,
    ResourceStorageService,
    SearchStorageService,
    FlashMessageService,
    HistoryService,
    HelperService,
    SettingsService,
    FilterService,
    GAService
  ) {
    $scope.genericDashboardActivated = GENERIC_DASHBOARD_ACTIVATED;

    function initializeSearchData() {
      $scope.searchData = {
        precision:
          $rootScope.settings && $rootScope.settings.precision
            ? parseInt($rootScope.settings.precision)
            : DEFAULT_PRECISION,
        dateFrom:
          $rootScope.settings && $rootScope.settings.dateFrom
            ? $rootScope.settings.dateFrom
            : DEFAULT_DATE_FROM,
        dateTo:
          $rootScope.settings && $rootScope.settings.dateTo
            ? $rootScope.settings.dateTo
            : DEFAULT_DATE_TO,
        searchProfileId:
          $rootScope.settings && $rootScope.settings.searchProfileId
            ? $rootScope.settings.searchProfileId
            : DEFAULT_SEARCH_PROFILE,
        collections:
          $rootScope.settings && $rootScope.settings.collections
            ? $rootScope.settings.collections
            : [],
        concept: {},
        refineConcepts: [],
        updatedRefinedConcepts: [],
        suggestions: [],
        page: 1,
        showSideBar: DEFAULT_SHOW_SIDEBAR,
        showSuggestions: false,
        masonryMode: DEFAULT_MASONRY_MODE,
        collectionsHaveBeenUpdated: false,
        creationDateFrom: null
      };
      $scope.searchData.filterByDate = !!(
        $scope.searchData.dateFrom || $scope.searchData.dateTo
      );

      initializeSearchResults();

      $scope.$broadcast('initializeSearchData');
    }

    function initializeSearchResults() {
      $scope.resources = [];
      $scope.meta = false;
      $scope.suggestedConcepts = [];
      $scope.conceptTree = [];
      $scope.types = [];
      $scope.relatedConcepts = [];
      $scope.nonRelatedConcepts = [];
      $scope.visibleTools = 'refine';
    }

    /*
    Get Filters directly after we receive concepts
    As we have several instances of FilterCtrl, we have to pass filter-related code
    on the MainCtrl to prevent duplicate number of API Calls
     */
    $scope.$on('conceptListPromiseSuccessful', function () {
      $scope.loadingFilters = true;
      var filterPromises = [];
      // we request filters asynchronously

      filterPromises.push(
        FilterService.list(
          $scope.searchData.terms,
          $scope.searchData.searchProfileId,
          $scope.searchData.collections,
          $scope.concepts.flatList,
          $scope.searchData.page,
          $scope.searchData.precision,
          $scope.searchData.dateFrom,
          $scope.searchData.dateTo,
          $scope.searchData.refineConcepts,
          $scope.concepts.unfoundTerms,
          $scope.concepts.originalConcepts,
          $scope.concepts.originalResponse,
          ($scope.searchData.requestedFilters || []).join('|'),
          $scope.searchData.creationDateFrom
        ).then(function (filter) {
          return ($scope.filters = $scope.filters.concat(filter));
        })
      );

      $q.all(filterPromises).then(
        function (results) {
          $scope.loadingFilters = false;

          // If we have "filterAmbiguity" we force sidebar to open
          angular.forEach(results, function (filterGroup) {
            angular.forEach(filterGroup, function (filter) {
              if (filter.id === 'filterAmbiguity') {
                $scope.searchData.showSidebar = true;
              }
            });
          });
        },
        function (error) {
          $scope.loadingFilters = false;
          if (
            typeof error.message !== 'undefined' &&
            typeof error.message.title !== 'undefined'
          ) {
            FlashMessageService.show(error.message.title, 'error');
          } else {
            FlashMessageService.show(
              $filter('translate')('messages.generalError'),
              'error'
            );
          }
        }
      );
    });

    // THE main search function
    $scope.search = function (searchData, mode) {
      $scope.searchData.page =
        typeof searchData.page !== 'undefined' ? searchData.page : 1;

      // if user modifies terms or collections, we admit that his query is different and we remove refine filters and creationDateFrom and go back to page 1
      if (
        $scope.searchData.collectionsHaveBeenUpdated ||
        typeof searchData.refineConcepts === 'undefined' ||
        (typeof $scope.lastTerms !== 'undefined' &&
          $scope.lastTerms !== searchData.terms)
      ) {
        searchData.refineConcepts = [];
        searchData.updatedRefinedConcepts = [];
        searchData.page = 1;
      }

      // if we type terms, we forget our creationDate to "exit" the mode "fromDashboard"
      if (searchData.terms) {
        searchData.creationDateFrom = false;
      }

      // if we use a different searchData than the current one (historicalSearchData, savedSearchData...), we update displayed search parameters
      $scope.searchData = searchData;

      // Close sidebar on mobile
      if ($window.innerWidth <= MOBILE_BREAKPOINT) {
        $scope.searchData.showSidebar = false;
        $rootScope.sidebarActive = false;
      }

      if (mode !== 'historical') {
        // we first generate an Id for that search and a date
        searchData.id = HelperService.generateId();
        searchData.date = new Date();
        $state.go('search', {
          q: searchData.terms,
          id: searchData.id,
          mode: ''
        });
      }

      $scope.$broadcast('search', true);

      // hide drop-downs and panels and adapt interface
      $scope.isDefinedMode = false;
      $scope.searchData.displayedDropdown = false;
      $scope.searchData.isSearchActive = true;
      $scope.searchData.showHistory = false;
      $scope.searchData.suggestions = [];
      $scope.filters = [];

      // filter by date or not
      if (!searchData.filterByDate) {
        searchData.dateFrom = null;
        searchData.dateTo = null;
      }

      // reset values
      if (typeof $scope.resources !== 'undefined') {
        initializeSearchResults();
      }

      // start loading spinner
      $scope.searchData.showDashboard = false;
      $scope.loadingResources = true;

      // we get Concepts from terms and search profile
      ConceptService.list(
        searchData.terms,
        searchData.searchProfileId,
        searchData.lang
      ).then(
        function (concepts) {
          // TODO : when refactoring this whole function, we don't need that first ConceptList API Call on "fromDashboard" mode
          if (mode === 'fromDashboard') {
            concepts.flatList = $scope.searchData.dashboardConceptFlatList;
            concepts.originalConcepts =
              $scope.searchData.dashboardOriginalConcepts;
            // no dates on fromDashboard mode
            $scope.searchData.dateFrom = null;
            $scope.searchData.dateTo = null;
          }

          // we store concepts in scope to use them in other controllers
          $scope.concepts = concepts;

          $scope.$broadcast('conceptListPromiseSuccessful');

          // we get Resources based on terms and the concepts that we just received
          ResourceService.list(
            searchData.terms,
            searchData.searchProfileId,
            searchData.collections,
            concepts.flatList,
            searchData.page,
            searchData.precision,
            searchData.dateFrom,
            searchData.dateTo,
            searchData.refineConcepts,
            concepts.unfoundTerms,
            concepts.originalConcepts,
            null,
            searchData.creationDateFrom,
            concepts.unfoundTerms
          ).then(
            function (resources) {
              $scope.loadingResources = false;
              $scope.resources = resources.data;
              $scope.meta = resources.meta;
              $scope.meta.terms = searchData.terms;

              var searchTooWide =
                $scope.meta.resultCount >= MAX_RECOMMENDED_RESULTS;
              if (searchTooWide) {
                GAService.event('Results', 'Search Too Wide');
              }

              $scope.searchTooWide = searchTooWide;

              $scope.$broadcast('resourceListPromiseSuccessful');

              // add query to history and refresh
              HistoryService.store(searchData).then(function () {
                $scope.$broadcast('searchDataStoredInHistory');
              });

              $scope.lastTerms = searchData.terms;
              $scope.searchData.collectionsHaveBeenUpdated = false;

              // we store current search parameters into user favorite settings
              if ($rootScope.referrer) {
                // track search in Piwik Analytics
                trackSearchInPiwik();
              } else {
                SettingsService.storeProperties({
                  lang: $rootScope.lang,
                  resourcesPerPage: parseInt($rootScope.resourcesPerPage),
                  precision: parseInt($scope.searchData.precision),
                  dateFrom: $scope.searchData.dateFrom,
                  dateTo: $scope.searchData.dateTo,
                  searchProfileId: $scope.searchData.searchProfileId,
                  collections: $scope.searchData.collections
                }).then(function () {
                  // track search in Piwik Analytics
                  trackSearchInPiwik();
                });
              }
            },
            function (error) {
              $scope.loadingResources = false;
              if (
                typeof error.message !== 'undefined' &&
                typeof error.message.title !== 'undefined'
              ) {
                FlashMessageService.show(error.message.title, 'error');
              } else {
                FlashMessageService.show(
                  $filter('translate')('messages.generalError'),
                  'error'
                );
              }
            }
          );
        },
        function (error) {
          FlashMessageService.show(
            $filter('translate')('messages.generalError'),
            'error'
          );
          $scope.loadingResources = false;
        }
      );
    };

    // track search in Piwik Analytics
    function trackSearchInPiwik() {
      if ($scope.searchData.page == 1) {
        if ($scope.searchData.refineConcepts.length) {
          var refineConcepts = '';
          for (var i = 0; i < $scope.searchData.refineConcepts.length; i++) {
            refineConcepts =
              refineConcepts + $scope.searchData.refineConcepts[i].label;
            if (i < $scope.searchData.refineConcepts.length - 1)
              refineConcepts = refineConcepts + '|';
          }
        } else {
          var refineConcepts = false;
        }
        if (window._paq)
          window._paq.push([
            'trackSiteSearch',
            $scope.meta.terms,
            refineConcepts,
            $scope.meta.resultCount
          ]);
      } else {
        // track Piwik Analytics Goal 5 : Pagination
        if (window._paq) window._paq.push(['trackGoal', 5]);
      }
    }

    function prepareSearch() {
      // initialize searchData object
      initializeSearchData();

      /*
         we create a storage object to be used by ResourceStorageCtrl and SearchStorageCtrl to ensure that values
         are keeping updated on change
         */
      $scope.storage = {};

      ResourceStorageService.list().then(function (results) {
        $scope.storage.savedResources = results;
      });

      SearchStorageService.list().then(function (savedSearches) {
        $scope.storage.savedSearches = savedSearches;
      });

      // we check if it's a defined mode, then if we have a queryId in URL we try to launch it, else we initialize a new search
      if (
        typeof $state.params.mode !== 'undefined' &&
        $state.params.mode === 'defined'
      ) {
        $scope.isDefinedMode = true;

        if (
          typeof $state.params.uri !== 'undefined' &&
          $state.params.searchProfileId
        ) {
          $scope.searchData.searchProfileId = $state.params.searchProfileId;
          $scope.$broadcast('search', true);
          ResourceService.listDefined(
            $state.params.searchProfileId,
            decodeURI($state.params.uri)
          ).then(function (resources) {
            $scope.loadingResources = false;
            $scope.resources = resources.data;
            $scope.meta = resources.meta;
            $scope.definedLabel = decodeURI($state.params.uri);
            $scope.meta.terms = $scope.searchData.terms;
          });
        }
      } else if (typeof $state.params.id !== 'undefined') {
        HistoryService.get($state.params.id).then(function (
          historicalSearchData
        ) {
          if (historicalSearchData) {
            $scope.search(historicalSearchData, 'historical');
          }
        });
      }
    }

    // toggle tab panes visibility
    $scope.toggleDropdown = function (pane) {
      if ($scope.searchData.displayedDropdown !== pane) {
        $scope.searchData.displayedDropdown = pane;
        // re-build precision range and prevent checkboxes hiding when tab not hidden
        if (pane === 'options') {
          setTimeout(function () {
            $scope.$broadcast('reCalcViewDimensions');
          }, 10);
        }
        $rootScope.paneActive = true;
      } else {
        $scope.closeDropdown();
      }
    };

    $scope.closeDropdown = function () {
      $scope.searchData.displayedDropdown = false;
      $rootScope.paneActive = false;
      angular
        .element('li.ms-NavBar-item.is-selected')
        .removeClass('is-selected');
    };

    $scope.reload = function () {
      initializeSearchData();
      $state.go(
        $state.current,
        {
          q: '',
          id: '',
          mode: '',
          uri: '',
          searchProfileId: ''
        },
        { reload: true }
      );
    };

    $scope.triggerResize = function () {
      $timeout(function () {
        $window.dispatchEvent(new Event('resize'));
      }, 100);
    };

    $scope.toggleSidebar = function () {
      $scope.searchData.showSidebar = !$scope.searchData.showSidebar;
      $rootScope.sidebarActive = $scope.searchData.showSidebar;
    };

    // We add watcher to uifNavBar directive item to apply dynamic class to body
    $scope.$watch(
      function () {
        return angular.element('.navbar__head').attr('class');
      },
      function (elementClass) {
        $rootScope.menuActive =
          elementClass && elementClass.indexOf('is-open') !== -1;
      },
      true
    );

    $scope.closeSearchTooWideModal = function () {
      $scope.searchTooWide = false;
    };

    $scope.resetOptions = function () {
      SettingsService.reset().then(function () {
        initializeSearchData();
        $scope.$broadcast('resetSearchOptions');
      });
    };

    $rootScope.$on('settingsReceived', function () {
      prepareSearch();
      // When we have search terms in "query" parameter automatically launch the search
      var terms = $state.params.query;

      if (
        terms &&
        $scope.searchData &&
        $scope.searchData.collections &&
        $scope.searchData.collections.length > 0
      ) {
        $scope.searchData.terms = terms;
        //start search
        $scope.search($scope.searchData);
      }
    });

    $rootScope.$on('enterBrowserHistoryState', function () {
      prepareSearch();
    });

    // Update dateFrom and dateTo visual date on real date change
    $scope.$watch('searchData.dateFrom', function (newValue) {
      if ($scope.searchData) {
        if (newValue) {
          $scope.searchData.formattedDateFrom = new Date(
            newValue
          ).toLocaleDateString($rootScope.lang === 'FR' ? 'fr-FR' : 'en-US');
        } else {
          $scope.searchData.formattedDateFrom = '';
        }
      }
    });

    $scope.$watch('searchData.dateTo', function (newValue) {
      if ($scope.searchData) {
        if (newValue) {
          $scope.searchData.formattedDateTo = new Date(
            newValue
          ).toLocaleDateString($rootScope.lang === 'FR' ? 'fr-FR' : 'en-US');
        } else {
          $scope.searchData.formattedDateTo = '';
        }
      }
    });

    $scope.setDate = function (modelName, dateString) {
      var dateForDatepicker = dateString.split('/').reverse();
      if ($rootScope.lang === 'FR') {
        // dd/mm/yyyy to yyyy-mm-dd
        dateForDatepicker = dateForDatepicker.join('-');
      } else {
        // mm/dd/yyyy to yyyy-mm-dd
        var tmp = dateForDatepicker[2];
        dateForDatepicker[2] = dateForDatepicker[1];
        dateForDatepicker[1] = tmp;
        dateForDatepicker = dateForDatepicker.join('-');
      }
      $scope.searchData[modelName] = dateForDatepicker;
    };

    // Translate dateFrom and dateTo on changing language
    $rootScope.$watch('lang', function (newValue) {
      if ($scope.searchData) {
        if ($scope.searchData.dateFrom) {
          $scope.searchData.formattedDateFrom = new Date(
            $scope.searchData.dateFrom
          ).toLocaleDateString(newValue === 'FR' ? 'fr-FR' : 'en-US');
        }
        if ($scope.searchData.dateTo) {
          $scope.searchData.formattedDateTo = new Date(
            $scope.searchData.dateTo
          ).toLocaleDateString(newValue === 'FR' ? 'fr-FR' : 'en-US');
        }
      }
    });
  }
);
