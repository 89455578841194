app.controller('SettingsCtrl', function ($rootScope, $translate, $state, $scope, SettingsService) {

    var self = this;

    self.setLanguage = function (lang) {
        $rootScope.lang = lang;
        $translate.use(lang);

        SettingsService.storeProperty('lang', lang);
    };

    self.setResourcesPerPage = function (resourcesPerPage) {
        SettingsService.storeProperty('resourcesPerPage', resourcesPerPage).then(function () {
            $rootScope.resourcesPerPage = resourcesPerPage;

            // Return to page one
            $scope.searchData.page = 1;

            if ($scope.searchData.creationDateFrom) {
                $scope.search($scope.searchData, 'fromDashboard');
            } else {
                $scope.search($scope.searchData);
            }

        });
    };

});
